import React, { KeyboardEvent, useState } from "react";
import { faSearch } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Grid, TextField, Button } from "@material-ui/core";

interface ISearchProps {
    filterText?: string;
    onChangeText: (filterText: string) => void;
    getLangString: (value: string) => string;
}

export const SearchBar = (props: ISearchProps): JSX.Element => {
    const [filterText, setFilterText] = useState(props.filterText ?? '');

    const onKeyUp = (event: KeyboardEvent) => {
        if (event.key === 'Enter') {
            props.onChangeText(filterText);
        }
    }

    return (
        <Grid container direction="row">
            <Grid item xs={12} className="search-item">
                <div className="select search-field">
                    <FontAwesomeIcon icon={faSearch} className="search-icon" />
                    <TextField
                        className="search-input"
                        value={filterText}
                        onChange={(event) => setFilterText(event?.target.value)}
                        onKeyUp={onKeyUp}
                    />
                </div>
                <Button className="primary-btn search-btn" onClick={() => props.onChangeText(filterText)}>
                    {props.getLangString('event.filter.search')}
                </Button>
            </Grid>
        </Grid>
    );
}
