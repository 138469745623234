import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import './ThemeMarker.scss';
import { IMarker, IMarkerShape, IPolyline, IVertices, MarkerSource } from '../../../store/Markers/Marker';
import { InfoBox } from './InfoBox';
import { MarkerDialogOpen, MarkerIndexDialogOpen, Polylines } from '../../../store/Markers/Selector';
import { isNullEmptyOrUndefined } from "../../../utils/helpers/ObjectHelper";
import { Polyline } from "./Polyline";
import { updatePolylines } from "../../../store/Markers/Actions";
import { SelectedFilters } from "../../../store/Filters/Selector";
import { vehicles } from "../../../constants/VehicleConstants";

export interface IMobileInfoBoxProps {
    getLangString: (value: string) => string;
    markers: IMarker[];
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    map: any;
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    maps: any;
}

const getEmptyMarker = (): IMarker => {
    return ({
        title: '',
        id: '',
        description: '',
        link: '',
        types: [],
        subTypes: [],
        source: MarkerSource.Other,
        markerShapes: [],
        deleted: false,
        published: true,
        detailsUrl: '',
        imageUrl: '',
        modified: new Date()
    });
}

export const MobileInfoBox = (props: IMobileInfoBoxProps): JSX.Element => {

    const [openMarker, setOpenMarker] = useState<IMarker>(getEmptyMarker());
    const [polylinePath, setPolylinePath] = useState<IVertices[][]>([]);
    const [showInfoBox, setShowInfoBox] = useState(false);

    const markerDialogOpen = useSelector(MarkerDialogOpen);
    const indexOpen = useSelector(MarkerIndexDialogOpen);
    const polylines = useSelector(Polylines);
    const selectedFilters = useSelector(SelectedFilters);

    const dispatch = useDispatch();

    useEffect(() => {
        const marker = props.markers.find(marker => marker.id === markerDialogOpen);
        if (marker) {
            handlePath(marker.markerShapes);
            setOpenMarker(marker);
            setShowInfoBox(true);
        } else {
            handlePolylines();
            setOpenMarker(getEmptyMarker());
            setShowInfoBox(false);
        }
    }, [markerDialogOpen, props.markers]);

    useEffect(() => {
        const marker = props.markers.find(marker => marker.id === markerDialogOpen);
        if (indexOpen && indexOpen > 0 && marker?.siblings && marker.siblings?.length >= indexOpen) {
            const siblingIndex = indexOpen - 1;
            const markerShapes = marker.siblings[siblingIndex].markerShapes;
            handlePath(markerShapes);
        } else if (marker) {
            handlePath(marker.markerShapes);
        }
    }, [indexOpen]);

    const handlePath = (markerShapes: IMarkerShape[]) => {
        setPolylinePath([]);
        const newPolylinePath: IVertices[][] = [];
        if (!isNullEmptyOrUndefined(markerShapes)) {
            markerShapes.forEach((markerShape: IMarkerShape) => {
                if (!isNullEmptyOrUndefined(markerShape.vertices) && markerShape.vertices.length > 0) {
                    newPolylinePath.push(markerShape.vertices)
                }
            });
            setPolylinePath(newPolylinePath);
        } else {
            setPolylinePath([]);
        }
    };

    const handlePolylines = () => {
        if (!isNullEmptyOrUndefined(polylines) && polylines.length > 0) {
            const polylinesToClean = polylines.filter(poly => !vehicles.some(v => poly.id.includes(v.name)));
            if (!isNullEmptyOrUndefined(polylinesToClean) && polylinesToClean.length > 0) {
                polylinesToClean.forEach((polyline: IPolyline) => {
                    // eslint-disable-next-line @typescript-eslint/no-explicit-any
                    polyline.polylines.forEach((poly: any) => {
                        if (!isNullEmptyOrUndefined(poly)) {
                            poly.setMap(null);
                        }
                    })
                })
                const newPolylines = polylines.filter(polyline => !polylinesToClean.some(poly => poly.id === polyline.id));
                dispatch(updatePolylines(newPolylines));
            }
        }
    }

    const getColorForMarkerShape = (marker: IMarker) => {
        const type = selectedFilters && marker && marker.types.length > 0 ?
            marker.types.find(t => selectedFilters.find(f => t.title === f.title)) :
            !selectedFilters && marker && marker.types.length > 0 ?
                marker.types[0] :
                undefined;

        const vehicle = vehicles.find(v => v.type === marker.icon);
        const color = type?.title !== "Turer" ? type?.class : vehicle ? vehicle.color : undefined;

        switch (color) {
          case 'dark-blue':
            return '#035e8e';
          case 'light-blue':
            return '#037dba';
          case 'light-blue-2':
            return '#00AAFF';
          case 'dark-green-2':
            return '#017033';
          case 'grey':
            return '#808080';
          case 'brown':
            return '#B59243';
          case 'dark-brown':
            return '#6d5827';
          case 'red':
            return '#D3454E';
          default:
            return '#035e8e';
        }
    };

    return (
        <React.Fragment>
            {showInfoBox &&
                <React.Fragment>
                    <InfoBox getLangString={props.getLangString} marker={openMarker} markerType={'mobile'} setShowInfoBox={setShowInfoBox} />
                    {polylinePath && polylinePath.length > 0 ?
                        <Polyline
                            id={openMarker.id}
                            listOfVertices={polylinePath}
                            map={props.map}
                            maps={props.maps}
                            color={getColorForMarkerShape(openMarker)}
                        />
                        :
                        <React.Fragment />
                    }
                </React.Fragment>
            }
        </React.Fragment>
    )
}
