import React from 'react';
import { faChevronLeft, faChevronRight } from '@fortawesome/free-solid-svg-icons'
import { IEvent } from '../../../store/Events/Event';
import './ThemeDatePicker.scss';
import { Button, Grid } from '@material-ui/core';
import moment from 'moment';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

export interface IDatePickerProps {
    getLangString: (value: string) => string;
    eventStart: Date;
    relatedEvents?: IEvent[] | undefined;
}

export const DateList = (props: IDatePickerProps): JSX.Element => {
    const { relatedEvents, eventStart, getLangString } = props;
    const [eventsCurrentMonth, setEventsCurrentMonth] = React.useState<IEvent[] | undefined>(relatedEvents?.filter((e) => new Date(eventStart).getMonth() === new Date(e.start).getMonth()));
    const [currentMonth, setCurrentMonth] = React.useState<number | undefined>(new Date(eventStart).getMonth());

    const handleDates = (nextMonth: boolean) => {
        if (relatedEvents && currentMonth) {
            if (nextMonth) {
                const nextMonth = currentMonth + 1;
                setCurrentMonth(nextMonth);
                const eventCurrentMonth = relatedEvents?.filter((e) => nextMonth === new Date(e.start).getMonth());
                setEventsCurrentMonth(eventCurrentMonth);
            } else {
                const prevMonth = currentMonth - 1;
                setCurrentMonth(prevMonth);
                const eventCurrentMonth = relatedEvents?.filter((e) => prevMonth === new Date(e.start).getMonth());
                setEventsCurrentMonth(eventCurrentMonth);
            }
        }
    }

    return (
        <Grid container direction="row" justifyContent="flex-start" alignItems="flex-start" className="date-list-container">
            {currentMonth &&
                <React.Fragment>
                    <Grid container direction="row" className="date-title">
                        <Grid item xs={6}>
                            {moment(new Date().setMonth(currentMonth)).format('MMMM YYYY')}
                        </Grid>
                        <Grid item xs={6}>
                            <div className="button-container">
                            <Button className="button" disabled={currentMonth <= new Date().getMonth()} onClick={() => handleDates(false)}><FontAwesomeIcon icon={faChevronLeft} className="icon" size="sm" /></Button>
                            <Button className="button" onClick={() => handleDates(true)}><FontAwesomeIcon icon={faChevronRight} className="icon" size="sm" /></Button>
                            </div>
                        </Grid>
                    </Grid>
                    {eventsCurrentMonth && eventsCurrentMonth.length > 0 ?
                        <React.Fragment>
                            {eventsCurrentMonth?.map((event: IEvent, index: number) =>
                                <React.Fragment key={index}>
                                    <Grid item xs={6}>
                                        {moment(event.start).format('D MMMM')}
                                    </Grid>
                                    <Grid item xs={6}>
                                        {moment(event?.start).format('HH:mm') === '00:00' && moment(event?.end).format('HH:mm') === '00:00' ?
                                            <span className="details-time">
                                                {getLangString('event.aroundTheClock')}
                                            </span>
                                            :
                                            <React.Fragment>
                                                <span className="details-time">
                                                {moment(event?.start).format('HH:mm')} - {moment(event?.end).format('HH:mm')}
                                                </span>
                                            </React.Fragment>
                                        }
                                    </Grid>
                                </React.Fragment>
                            )}
                        </React.Fragment>
                        :
                        <span>
                            {props.getLangString('event.noEventsThisMonth')}
                        </span>
                    }

                </React.Fragment>
            }

        </Grid>
    )
}