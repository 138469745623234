import { FilterActionTypes, GET_FILTERS_BEGIN, GET_FILTERS_SUCCESS, GET_FILTERS_FAILURE, UPDATE_SELECTED_FILTERS, UPDATE_SELECTED_FILTERS_FAILURE, CLEAN_SELECTED_FILTERS, IFilterState, SET_SELECTED_FILTER_TITLE } from './ActionTypes';

const initialState: IFilterState = {
    filterList: [],
    loading: false,
    selectedFilters: [],
};

const filterReducer = (state = initialState, action: FilterActionTypes) : IFilterState => {
    switch (action.type) {
        case GET_FILTERS_BEGIN:
            return {
                ...state,
                loading: true
            };

        case GET_FILTERS_SUCCESS:
            return {
                ...state,
                loading: false,
                filterList: action.payload
            }

        case GET_FILTERS_FAILURE:
            return {
                ...state,
                loading: false,
                error: action.payload
            }

        case UPDATE_SELECTED_FILTERS: 
            return {
                ...state,
                selectedFilters: action.payload
            }
        
        case UPDATE_SELECTED_FILTERS_FAILURE:
            return {
                ...state,
                error: action.payload
            }

        case CLEAN_SELECTED_FILTERS:
            return {
                ...state,
                selectedFilters: []
            }
        case SET_SELECTED_FILTER_TITLE:
            return {
                ...state,
                selectedFilterTitle: action.payload
            }
        default:
            return state;
    }
}

export default filterReducer;