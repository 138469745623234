import React from "react";
import { GoogleMaps } from '../components/map/google/GoogleMaps';
import { MapFilter } from '../components/map/filter/MapFilter';
import { Live } from '../components/common/live/Live';

export interface IHomeProps {
    getLangString: (value: string) => string;
}

export const Home = (props: IHomeProps) : JSX.Element => {
    return (
        <React.Fragment>
            <Live getLangString={props.getLangString}/>
            <GoogleMaps getLangString={props.getLangString}/>
            <MapFilter getLangString={props.getLangString}/>
        </React.Fragment>
    );
};