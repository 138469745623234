import React, { KeyboardEvent, MouseEvent, useEffect, useState } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCircle, IconName } from '@fortawesome/free-solid-svg-icons'
import { IMarker, IMarkerType } from '../../../store/Markers/Marker';
import { useSelector } from 'react-redux';
import { MarkerIsLiveHours } from '../../../store/Settings/Selector';
import { MarkerIsLive } from '../../../utils/helpers/MarkerHelper';
import { SelectedFilters } from '../../../store/Filters/Selector';
import { ShowAllLiveMarkers } from '../../../store/Markers/Selector';
import { isNullEmptyOrUndefined } from '../../../utils/helpers/ObjectHelper';

export interface IPinProps {
    getLangString: (value: string) => string;
    marker: IMarker,
    onClick: (e?: MouseEvent | KeyboardEvent) => void;
    active: boolean;
}

export const Pin = (props: IPinProps): JSX.Element => {

    const markerIsLiveHours = useSelector(MarkerIsLiveHours);
    const showAllLiveMarkers = useSelector(ShowAllLiveMarkers);
    const [markerIsLive, setMarkerIsLive] = useState<boolean>(false);
    const selectedFilters = useSelector(SelectedFilters);

    const getType = () => {
        let markerType: IMarkerType | undefined = undefined;
        const markerHasTypes = props.marker && props.marker.types.length > 0;
        if (selectedFilters && markerHasTypes)
        {
            markerType = props.marker.types.find(t => selectedFilters.find(f => t.title === f.title));

            if (isNullEmptyOrUndefined(markerType) && showAllLiveMarkers)
            {
                markerType = props.marker.types[0];
            }
        } else if (!selectedFilters && markerHasTypes)
        {
            markerType = props.marker.types[0]
        }
        return markerType;
    }

    const type = getType();

    useEffect(() => {
        setMarkerIsLive(MarkerIsLive(props.marker, markerIsLiveHours));
    }, [props.marker]);

    const handleKeyDown = (event: KeyboardEvent) => {
        if (event?.key === 'Enter') {
            props.onClick(event);
        }
    }

    const markerisAlwaysAvailable = () => {
        return props?.marker?.description != null && (
            props?.marker?.description === "<div><b>Tillgänglighetstider</b></div><div>01 January-31 December</div><div>Alltid tillgänglig</div>" ||
            props?.marker?.description === "<div><b>Tillgänglighetstider</b></div><div>Alltid tillgänglig</div>");
    };

    return (

        <React.Fragment>
            <div
                tabIndex={0}
                className={'marker ' + (type?.class ?? '') + (props.active ? ' active' : '') + (markerIsLive ? ' live' : '')}
                onClick={(event) => props.onClick(event)} onKeyDown={handleKeyDown}>
                <div className="icon-container">
                    <div><FontAwesomeIcon icon={['fas', (type?.icon ?? 'list-ul') as IconName]} aria-label={props.getLangString('filter.dot')} /></div>
                </div>
                {markerIsLive &&
                    <div className="live-pin" role="complementary">
                        <FontAwesomeIcon icon={faCircle} className="circle" />
                    </div>
                }

                {markerisAlwaysAvailable() &&
                    <div className="live-pin live" role="complementary">
                        <FontAwesomeIcon icon={faCircle} className="circle always-available" />
                    </div>
                }

                {props.marker.siblings && props.marker.siblings.length > 0 &&
                    <div className={`multiple-pins ${props.active ? 'active' : '' + (type?.class ?? '')}`}>
                        <span className="text">
                            {props.marker.siblings.length + 1}
                        </span>
                    </div>
                }
            </div>

        </React.Fragment>

    );
}