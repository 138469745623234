import React from 'react';
import { Container } from '@material-ui/core';
import { EventDetails } from '../components/event/EventDetails'

export interface IEventProps{
    getLangString: (value: string) => string;
}

export const Event = (props: IEventProps) : JSX.Element => {
    return (
        <Container role="main">
            <EventDetails getLangString={props.getLangString}/>
        </Container>
    );
};