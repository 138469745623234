export const SET_TITLE = 'SET_TITLE';
export const SET_TITLE_FAILURE = 'SET_TITLE_FAILURE';
export const CLEAR_TITLE = 'CLEAR_TITLE';
export const CLEAR_TITLE_FAILURE = 'CLEAR_TITLE_FAILURE';

export interface IBreadcrumbs {
    title: string;
    error?: string;
}

interface SetTitle {
    type: typeof SET_TITLE,
    payload: string;
}

interface SetTitleFailure {
    type: typeof SET_TITLE_FAILURE,
    payload: string;
}

interface ClearTitle {
    type: typeof CLEAR_TITLE,
}

interface ClearTitleFailure {
    type: typeof CLEAR_TITLE_FAILURE,
    payload: string;
}

export type BreadcrumbsActionTypes = SetTitle | SetTitleFailure | ClearTitle | ClearTitleFailure;