/* eslint-disable @typescript-eslint/no-explicit-any */
import React from 'react';
import { Grid } from '@material-ui/core';
import './ThemeAboutLive.scss';
import Prismic from '@prismicio/client';
import { RichText } from 'prismic-reactjs';
import { Document } from '@prismicio/client/types/documents';
import { PRISMIC_URL, PRISMIC_TOKEN } from '../../Constants';
import { PrismicRichText } from '../common/prismic/PrismicRichText';
import { SEO } from '../common/seo/SEO';
import { useTranslation } from 'react-i18next';

export const AboutLive = (): JSX.Element => {
  const client = Prismic.client(PRISMIC_URL, { accessToken: PRISMIC_TOKEN });
  const [doc, setDocData] = React.useState<Document | undefined>();
  const { t } = useTranslation('common');

  React.useEffect(() => {
    const fetchData = async () => {
      const doc = await client.getSingle('about', {});
      setDocData(doc);
    };
    fetchData();
  }, []);

  return (
    <React.Fragment>
      <SEO title={t('topNavigation.aboutRonneby')} />
      {doc && (
        <React.Fragment>
          <div className="about-wrapper" role="article">
            <Grid container direction="row" role="feed">
              <Grid item xs={12} role="article">
                <Grid item xs={12} md={8}>
                  <h1>{RichText.asText(doc.data.title)}</h1>
                </Grid>
              </Grid>
            </Grid>
            <Grid container direction="row">
              <Grid item xs={12} md={8} className="about-main">
                <PrismicRichText render={doc.data.body} />
              </Grid>
              <Grid item xs={12} md={4} className="about-tile">
                {doc.data.contacts &&
                  doc.data.contacts.map((contact: any, index: number) => {
                    return (
                      <div key={index}>
                        <PrismicRichText render={contact.contacttitle} />
                        <PrismicRichText render={contact.contactbody} />
                      </div>
                    );
                  })}
              </Grid>
            </Grid>
          </div>
        </React.Fragment>
      )}
    </React.Fragment>
  );
};
