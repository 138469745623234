import { IMarker, MarkerSource } from '../../store/Markers/Marker';

// eslint-disable-next-line @typescript-eslint/no-inferrable-types
export const MarkerIsLive = (marker: IMarker, markerIsLiveHours: number, shouldHaveDynamicContent: boolean = true): boolean => {
    const timeLimit = new Date();
    timeLimit.setHours(timeLimit.getHours() - markerIsLiveHours);

    const modifiedWithinTimeLimit = marker.modified.toString() > timeLimit.toISOString();
    const hasDynamicContent = marker.dynamicContent !== undefined && marker.dynamicContent !== null && marker.dynamicContent.properties.length > 0;
    const liveMarkerSource = marker.source === MarkerSource.Iot || marker.source === MarkerSource.Event;
    const liveMarkerType = marker.subTypes ? marker.subTypes?.some(f => f.title === "Olycka") : false;
    const isHolidayActivities = marker.types?.some(t => t.title === "Lovaktiviteter") ? true : false;

    return (((shouldHaveDynamicContent && hasDynamicContent) || !shouldHaveDynamicContent) &&
        modifiedWithinTimeLimit &&
        liveMarkerSource && !isHolidayActivities) ||
        liveMarkerType;
}