import React from 'react'
import { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { getEvents } from "../../store/Events/Actions";
import { IEvent } from '../../store/Events/Event';
import { Events, EventsLoading, FilteredEvents } from "../../store/Events/Selector";
import { EventTile } from './EventTile'
import { Loader } from '../common/loader/Loader';
import './ThemeEvent.scss';
import { Grid, Button } from '@material-ui/core';
import { EventsPageScrollPosition } from '../../store/Events/Selector';
import { setEventsPageScrollPosition } from '../../store/Events/Actions';

export interface IEventListProps {
    getLangString: (value: string) => string;
}

export const EventList = (props: IEventListProps): JSX.Element => {

    const dispatch = useDispatch();
    const events = useSelector(Events);
    const filteredEvents = useSelector(FilteredEvents);
    const eventsLoading = useSelector(EventsLoading);
    const eventsPageScrollPosition = useSelector(EventsPageScrollPosition);

    useEffect(() => {
        if ((!events || events.length === 0) && !eventsLoading) {
            dispatch(getEvents());
        }
    }, [dispatch, events, eventsLoading]);

    useEffect(() => {
        if (filteredEvents.length > 0 && eventsPageScrollPosition > 0) {
            window.scrollTo({ top: eventsPageScrollPosition });
            dispatch(setEventsPageScrollPosition(0));
        }
    }, [filteredEvents, eventsPageScrollPosition]);

    useEffect(() => {
        return () => {
            dispatch(setEventsPageScrollPosition(window.scrollY));
        }
    }, []);

    return (
        <React.Fragment>
            {eventsLoading ?
                <Loader getLangString={props.getLangString} />
                :
                <React.Fragment>
                    <Grid container direction="row" className="eventlist-container" role="feed">
                        {filteredEvents && filteredEvents.map((event: IEvent, index: number) =>
                            <EventTile getLangString={props.getLangString} event={event} key={index} />
                        )}
                        {(filteredEvents && filteredEvents.length === 0) &&
                            <React.Fragment>
                                <h4>{props.getLangString('event.noEvents')}</h4>
                            </React.Fragment>
                        }
                    </Grid>
                    <Grid container direction="row" className="eventlist-button-container">
                        <Grid item xs={12} className="navigation-option">
                            <Button variant="outlined" onClick={() => window.scrollTo({ top: 0, behavior: 'smooth' })} className="back-to-top-button blue">{'Tillbaka till toppen'}</Button>
                        </Grid>
                    </Grid>
                </React.Fragment>
            }
        </React.Fragment>
    )
}
