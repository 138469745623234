import { applyMiddleware, createStore } from "redux";
import reducer from "./RootReducer";
import thunk from 'redux-thunk';
import { createLogger } from 'redux-logger'

import { composeWithDevTools } from 'redux-devtools-extension';

const logger = createLogger({
    collapsed: true
});

const Store = createStore(reducer, undefined, composeWithDevTools(applyMiddleware(thunk, logger)));

export default Store;