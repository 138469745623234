import axios from 'axios';
import { API_ROOT } from '../../Constants';

import { ThunkAction } from 'redux-thunk'
import { RootState } from '../RootReducer';
import { Action } from 'redux';
import * as EventActions from './ActionTypes'
import { IEvent } from './Event';

export const getEvents = (): ThunkAction<void, RootState, unknown, Action<string>> => async dispatch => {
    try {
        dispatch({ type: EventActions.GET_EVENTS_BEGIN });

        const result = await axios.get(`${API_ROOT}/event`);
        dispatch({
            type: EventActions.GET_EVENTS_SUCCESS,
            payload: result.data
        });
    } catch (error) {
        console.error(error);
        dispatch({ type: EventActions.GET_EVENTS_FAILURE, payload: error })
    }
}

export const setFilteredEvents = (filteredEvents: IEvent[]): ThunkAction<void, RootState, unknown, Action<string>> => async dispatch => {
    try {
        dispatch({
            type: EventActions.SET_FILTERED_EVENTS,
            payload: filteredEvents
        });

    } catch (error) {
        console.error(error);
        dispatch({ type: EventActions.SET_FILTERED_EVENTS_FAILURE, payload: error })
    }
}

export const setEventsPageScrollPosition = (scrollPosition: number): ThunkAction<void, RootState, unknown, Action<string>> => async dispatch => {
    try {
        dispatch({
            type: EventActions.SET_EVENTS_PAGE_SCROLL_POSITION,
            payload: scrollPosition
        });

    } catch (error) {
        console.error(error);
        dispatch({ type: EventActions.SET_EVENTS_PAGE_SCROLL_POSITION_FAILURE, payload: error })
    }
}

export const setEventsPageFilters = (eventsPageFilters: EventActions.IEventFilters): ThunkAction<void, RootState, unknown, Action<string>> => async dispatch => {
    try {
        dispatch({
            type: EventActions.SET_EVENTS_PAGE_FILTERS,
            payload: eventsPageFilters
        });

    } catch (error) {
        console.error(error);
        dispatch({ type: EventActions.SET_EVENTS_PAGE_FILTERS_FAILURE, payload: error })
    }
}

export const clearEventsPageFilters = (): ThunkAction<void, RootState, unknown, Action<string>> => async dispatch => {
    try {
        dispatch({
            type: EventActions.CLEAR_EVENTS_PAGE_FILTERS
        });

    } catch (error) {
        console.error(error);
        dispatch({ type: EventActions.CLEAR_EVENTS_PAGE_FILTERS_FAILURE, payload: error })
    }
}