import React from 'react';
import GoogleMap from 'google-map-react';
import './ThemeControl.scss';
import { faCrosshairs } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Button } from '@material-ui/core';

export interface IPositionControlProps {
    handleCenter: (position: GoogleMap.Coords) => void;
}

export const PositionControl = (props: IPositionControlProps): JSX.Element => {
    const centerMap = () => {
        navigator.geolocation.getCurrentPosition((position) => {
            const coords: GoogleMap.Coords = {
                lat: position.coords.latitude,
                lng: position.coords.longitude
            }
            props.handleCenter(coords);
        })
    }

    return (
        <div className="position control">
            <Button className="position-control" onClick={() => centerMap()}>
                <FontAwesomeIcon icon={faCrosshairs} className="control-icon" />
            </Button>
        </div>
    );
}

