// eslint-disable-next-line
export const isNullEmptyOrUndefined = (valueToValidate: any): boolean => {
    if (valueToValidate === null) {
        return true;
    }
    if (valueToValidate == null) {
        return true;
    }
    if (valueToValidate === undefined) {
        return true;
    }
    if (valueToValidate === '') {
        return true;
    }
    if (Object.prototype.hasOwnProperty.call(valueToValidate, 'length') && valueToValidate.length === 0) {
        return true;
    }
    if (Object.keys(valueToValidate).length === 0) {
        return true;
    }
    return false;
};