import {
    CLEAR_EVENTS_PAGE_FILTERS, CLEAR_EVENTS_PAGE_FILTERS_FAILURE, EventActionTypes, GET_EVENTS_BEGIN, GET_EVENTS_FAILURE, GET_EVENTS_SUCCESS,
    IEventState, SET_EVENTS_PAGE_FILTERS, SET_EVENTS_PAGE_FILTERS_FAILURE, SET_EVENTS_PAGE_SCROLL_POSITION, SET_EVENTS_PAGE_SCROLL_POSITION_FAILURE,
    SET_FILTERED_EVENTS, SET_FILTERED_EVENTS_FAILURE
} from './ActionTypes';

const initialState: IEventState = {
    eventList: [],
    filteredEventList: [],
    categoryList: [],
    loading: false,
    eventsPageState: {
        scrollPosition: 0
    }
};

const eventReducer = (state = initialState, action: EventActionTypes): IEventState => {
    switch (action.type) {
        case GET_EVENTS_BEGIN:
            return {
                ...state,
                loading: true
            };

        case GET_EVENTS_SUCCESS:
            return {
                ...state,
                loading: false,
                eventList: action.payload.events,
                categoryList: action.payload.categories
            }

        case GET_EVENTS_FAILURE:
            return {
                ...state,
                loading: false,
                error: action.payload
            }

        case SET_FILTERED_EVENTS:
            return {
                ...state,
                filteredEventList: action.payload
            }

        case SET_FILTERED_EVENTS_FAILURE:
            return {
                ...state,
                error: action.payload
            }
        case SET_EVENTS_PAGE_SCROLL_POSITION:
            return {
                ...state,
                eventsPageState: { ...state.eventsPageState, scrollPosition: action.payload }
            }

        case SET_EVENTS_PAGE_SCROLL_POSITION_FAILURE:
            return {
                ...state,
                error: action.payload
            }

        case SET_EVENTS_PAGE_FILTERS:
            return {
                ...state,
                eventsPageState: {
                    ...state.eventsPageState,
                    filters: {
                        date: action.payload.date,
                        categories: action.payload.categories,
                        text: action.payload.text
                    }
                }
            }

        case SET_EVENTS_PAGE_FILTERS_FAILURE:
            return {
                ...state,
                error: action.payload
            }

        case CLEAR_EVENTS_PAGE_FILTERS:
            return {
                ...state,
                eventsPageState: {
                    ...state.eventsPageState,
                    filters: undefined
                }
            }

        case CLEAR_EVENTS_PAGE_FILTERS_FAILURE:
            return {
                ...state,
                error: action.payload
            }
        default:
            return state;
    }
}

export default eventReducer;