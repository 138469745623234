import React from 'react';
import Button from '@material-ui/core/Button';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { IconName } from '@fortawesome/free-solid-svg-icons'
import './ThemeMapFilter.scss';


export interface IToggleMenuButtonProps {
    getLangString: (value: string) => string;
    showingMenu: boolean
    onClick: (e?: Event) => void
}

export const ToggleMenuButton = (props: IToggleMenuButtonProps): JSX.Element => {


    const text = props.showingMenu ? props.getLangString('filter.hidePins') : props.getLangString('filter.showPins')
    const icon = props.showingMenu ? "map-marked-alt" : "map-marker-alt"

    return (
        <Button onClick={() => props.onClick()} className="filter-toggle-button">
            <span>
                <FontAwesomeIcon size="lg" icon={['fas', icon as IconName]} />
            </span>
            <span>
                {text}
            </span>
        </Button>
    )
}