import { IBreadcrumbs, BreadcrumbsActionTypes, SET_TITLE, SET_TITLE_FAILURE, CLEAR_TITLE, CLEAR_TITLE_FAILURE } from "./ActionTypes";

const initialState: IBreadcrumbs = {
    title: ''
};

const breadcrumbsReducer = (state = initialState, action: BreadcrumbsActionTypes): IBreadcrumbs => {
    switch (action.type) {
        case SET_TITLE:
            return {
                ...state,
                title: action.payload
            }

        case SET_TITLE_FAILURE:
            return {
                ...state,
                error: action.payload
            }

        case CLEAR_TITLE:
            return {
                ...state,
                title: ""
            }

        case CLEAR_TITLE_FAILURE:
            return {
                ...state,
                error: action.payload
            }
        default:
            return state;
    }
}

export default breadcrumbsReducer;