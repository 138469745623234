import { faCircle } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import React, { MouseEvent, KeyboardEvent, useState, useEffect } from 'react'
import { useSelector } from 'react-redux';
import { SelectedFilters } from '../../../store/Filters/Selector';
import { IMarker } from '../../../store/Markers/Marker';
import { MarkerIsLiveHours } from '../../../store/Settings/Selector';
import { MarkerIsLive } from '../../../utils/helpers/MarkerHelper';

export interface IDotProps {
    getLangString: (value: string) => string;
    marker: IMarker;
    onClick: (e?: MouseEvent | KeyboardEvent) => void;
    active: boolean;
    color?: string;
}

export const Dot = (props: IDotProps): JSX.Element => {

    const markerIsLiveHours = useSelector(MarkerIsLiveHours);
    const [markerIsLive, setMarkerIsLive] = useState<boolean>(false);
    const selectedFilters = useSelector(SelectedFilters);
    const type = selectedFilters && props.marker && props.marker.types.length > 0 ?
        props.marker.types.find(t => selectedFilters.find(f => t.title === f.title)) :
        !selectedFilters && props.marker && props.marker.types.length > 0 ?
            props.marker.types[0] :
            undefined;
    const color = props.color ?? type?.class ?? '';

    useEffect(() => {
        setMarkerIsLive(MarkerIsLive(props.marker, markerIsLiveHours, false));
    }, [props.marker]);

    const handleKeyDown = (e: KeyboardEvent) => {
        if (e?.key === 'Enter') {
            props.onClick(e);
        }
    }

    return (
        <React.Fragment>
            <div className="dot-marker" onKeyDown={handleKeyDown} tabIndex={0}>
                <div className={'dot-container' + (markerIsLive ? ' live' : '')}>
                    <div>
                        <FontAwesomeIcon
                            icon={faCircle}
                            className={'circle ' + color + (props.active ? ' active' : '')}
                            onClick={(event) => props.onClick(event)} aria-label={props.getLangString('filter.dot')}
                        />
                    </div>
                </div>
            </div>
        </React.Fragment>
    );
}
