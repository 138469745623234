import React from 'react'
import { Grid, Container, Button } from '@material-ui/core';
import './ThemeNotFound.scss';

export interface INotFoundProps {
    getLangString: (value: string) => string;
}

export const NotFound = (props: INotFoundProps) : JSX.Element => {
    return (
        <Container role="main">
            <div className="not-found-wrapper" role="article">
                <Grid container direction="row">
                    <Grid item xs={12}>
                        <h1>{props.getLangString('errorHandling.cantFindPage')}</h1>

                        <Button href="/" className="back-button">
                            <span> {props.getLangString('errorHandling.backToStartPage')} </span>
                        </Button >
                    </Grid>
                </Grid>
            </div>
        </Container>
    );
};