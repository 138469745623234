import React, { useEffect, useState } from 'react';
import ReactGA from 'react-ga4';
import { EmailShareButton, FacebookIcon, FacebookShareButton, WhatsappIcon, WhatsappShareButton } from 'react-share';
import { faEnvelope, faLink, faShare } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Button, Grid, Link } from '@material-ui/core';
import './ThemeShare.scss';
import useDeviceDetect from '../../../utils/hooks/DeviceDetect';

interface IShareProps {
    getLangString: (value: string) => string;
    title: string;
    onInfoBox?: boolean;
    showIcon?: boolean;
    className?: string;
}

export const Share = (props: IShareProps): JSX.Element => {
    const pageTitle = props.getLangString('global.pageTitle');
    const { isMobile } = useDeviceDetect();
    const [menuOpen, setMenuOpen] = useState((props.onInfoBox || isMobile) ? false : true); 
    const title = props.title + ' | '+ pageTitle;
    const text = `Tips! Klicka på länken för att läsa mer om ${props.title} på ${pageTitle}:`
    const url = window.location.href;

    useEffect(() => {
        setMenuOpen((props.onInfoBox || isMobile) ? false : true);
    }, [isMobile]);

    const handleClick = async (event: React.MouseEvent<HTMLDivElement | HTMLButtonElement| MouseEvent>) => {
        preventDefault(event);
        if (isMobile) {
            if (navigator.share) {
                try {
                    await navigator.share({ title: title, url: url, text: text });
                    ReactGA.event({ category: 'Share', action: `Shared page on mobile, ${props.title}` });
                } catch (error) {
                    console.log('Error sharing', error);
                }
            }
        } else {
            setMenuOpen(!menuOpen);
        }
    }

    const handleMenuClick = (socialMedia: string, event?: React.MouseEvent<HTMLDivElement|HTMLButtonElement|MouseEvent>) => {
        if (event) preventDefault(event);
        ReactGA.event({ category: 'Share', action: `Shared page with ${socialMedia}, ${props.title}` });
    }

    const preventDefault = (event: React.MouseEvent<HTMLDivElement|HTMLButtonElement|MouseEvent>) => {
        event?.stopPropagation();
        event?.preventDefault();
    }

    return (
        <div className={`share`}>
            {(props.onInfoBox || isMobile) &&
                <div className={`${props.className}`} onClick={(event: React.MouseEvent<HTMLDivElement|MouseEvent>) => handleClick(event)}>
                    <Link>
                        {props.getLangString('global.share')}
                        {props.showIcon &&
                            <FontAwesomeIcon icon={faShare} className={'icon'} />
                        }
                    </Link>
                </div>
            }
            {menuOpen &&
                <div className={`share-menu ${props.onInfoBox ? 'infoBox' : ''} ${props.className}`}>
                    <Grid container spacing={props.onInfoBox ? 1 : 0}>
                        <Grid item xs={props.onInfoBox ? 3 : 1} className="button-social" title={'Facebook'}>
                            <FacebookShareButton url={url} quote={text} onClick={(event:  React.MouseEvent<HTMLButtonElement|MouseEvent>) => handleMenuClick('Facebook', event)}>
                                <FacebookIcon round size={25} />
                            </FacebookShareButton>
                        </Grid>
                        <Grid item xs={props.onInfoBox ? 3 : 1} className="button-social" title={'WhatsApp'}>
                            <WhatsappShareButton url={url} title={text} onClick={(event: React.MouseEvent<HTMLButtonElement|MouseEvent>) => handleMenuClick('WhatsApp', event)}>
                                <WhatsappIcon round size={25} />
                            </WhatsappShareButton>
                        </Grid>
                        <Grid item xs={props.onInfoBox ? 3 : 1} className="button-social" title={'E-post'} onClick={(event: React.MouseEvent<HTMLDivElement|MouseEvent>) => handleMenuClick('Email', event)}>
                            <EmailShareButton url={url} subject={title} body={text} separator={'\n'} beforeOnClick={() => handleMenuClick('Email')}>
                                <FontAwesomeIcon icon={faEnvelope} className='menu-icon' size='lg' />
                            </EmailShareButton>
                        </Grid>
                        <Grid item xs={props.onInfoBox ? 3 : 1} className="button-social" title={'Kopiera'}>
                            <Button onClick={(event: React.MouseEvent<HTMLButtonElement|MouseEvent>) => { navigator.clipboard.writeText(url); handleMenuClick('Copy link', event) }}>
                                <FontAwesomeIcon icon={faLink} className="menu-icon" size="lg" />
                            </Button>
                        </Grid>
                    </Grid >
                </div>
            }
        </div>
    );
}
