import React from 'react';
import { Container } from '@material-ui/core';
import { AboutLive } from '../components/about/AboutLive';

export const About = (): JSX.Element => {
    return (
        <Container role="main">
            <AboutLive />
        </Container>
    );
};