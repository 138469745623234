import { Grid, Container } from '@material-ui/core';
import React from 'react';
import { Report } from '../components/tableau/Report';

export const ViewReport = () : JSX.Element => {
    return (
        <Container>
            <Grid container direction="row">
                <Grid item xs={12}>
                    <Report />
                </Grid>
            </Grid>
        </Container>
    );
}