import React from 'react';
import AdapterDateFns from '@material-ui/lab/AdapterDateFns';
import LocalizaitonProvider from '@material-ui/lab/LocalizationProvider';
import svLocale from 'date-fns/locale/sv';
import { PickersDay, DayPicker } from '@material-ui/lab';
import './ThemeDatePicker.scss';
import { Grid } from '@material-ui/core';

export interface IDatePickerProps {
    getLangString: (value: string) => string;
    date: Date | null;
    setDate: (selectedValue: Date | null) => void;
}

export const DatePicker = (props: IDatePickerProps): JSX.Element => {
    const { setDate } = props;

    return (
        <Grid container direction="row" justifyContent="flex-start" alignItems="flex-start">
            <Grid item xs={12}>
                <LocalizaitonProvider dateAdapter={AdapterDateFns} locale={svLocale}>
                    <DayPicker
                        reduceAnimations={true}
                        allowKeyboardControl={false}
                        date={props.date}
                        onChange={(newDate) => setDate(newDate)}
                        onYearChange={(newDate) => setDate(newDate)}
                        disablePast={true}
                        renderDay={(day, higlightedDays, DayComponentProps) => {
                            const outDated = !DayComponentProps.outsideCurrentMonth && day < new Date();
                            return (
                                <React.Fragment key={day.getDate()}>
                                        <PickersDay aria-label={`${props.getLangString('event.selectDate')} ${day.getDate()}`} {...DayComponentProps} className={outDated ? 'outdated' : ''} />
                                </React.Fragment>
                            );
                        }}
                    />
                </LocalizaitonProvider>
            </Grid>
        </Grid>
    )
}