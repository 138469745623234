import React from 'react';
import './ThemeLive.scss';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faCircle } from '@fortawesome/free-solid-svg-icons'

export interface ILiveProps {
    getLangString: (value: string) => string;
}

export const Live = (props: ILiveProps): JSX.Element => {
    return (
        <div className="live live-logo" role="complementary" aria-label={props.getLangString('global.live')}>
            {props.getLangString('global.live')} <FontAwesomeIcon icon={faCircle} className="circle" />
        </div>
    )
}