import { combineReducers } from 'redux'
import appStateReducer from './AppState/Reducer'
import markerReducer from './Markers/Reducer'
import eventsReducer from './Events/Reducer'
import filterReducer from './Filters/Reducer'
import settingsReducer from './Settings/Reducer'
import breadcrumbsReducer from './Breadcrumbs/Reducer'


const reducer = combineReducers({
    appState: appStateReducer,
    markers: markerReducer,
    events: eventsReducer,
    filters: filterReducer,
    settings: settingsReducer,
    breadcrumbs: breadcrumbsReducer
});

export default reducer;

export type RootState = ReturnType<typeof reducer>
