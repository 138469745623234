import { IVehicle } from "../store/Markers/Marker";
import trainImage from '../images/Train.svg'
import astridImage from '../images/Astrid.svg';
import busImage from '../images/Bus.svg';
import fyrskarImage from '../images/Fyrskar.svg';

export const vehicles: IVehicle[] = [
    {
        name: 'train',
        image: trainImage,
        type: 'train',
        color: 'red'
    },
    {
        name: 'astrid',
        image: astridImage,
        type: 'astrid',
        color: 'dark-blue'
    },
    {
        name: 'karoline',
        image: astridImage,
        type: 'karoline',
        color: 'dark-blue'
    },
    {
        name: 'bus',
        image: busImage,
        type: 'bus',
        color: 'grey'
    },
    {
        name: 'fyrskar',
        image: fyrskarImage,
        type: 'fyrskar',
        color: 'dark-blue'
    }
]