import React from 'react';
import './ThemeError.scss';
import { Button, Container } from '@material-ui/core';

interface IErrorBannerProps {
    getLangString: (value: string) => string;
    errorMessage: string;
}

const ErrorBanner = (props: IErrorBannerProps): JSX.Element => {

    return (
        <div className="overlay">
            <Container className="error-banner">
                {props.errorMessage === "Network Error" ?
                    <React.Fragment>
                        <h1>{props.getLangString('errorHandling.networkError')}</h1>
                        <h4> {props.getLangString('errorHandling.tryAgain')} </h4>
                    </React.Fragment>
                    :
                    <React.Fragment>
                        <h1>{props.getLangString('errorHandling.error')}</h1>
                        <h4> {props.getLangString('errorHandling.noContact')} </h4>
                    </React.Fragment>
                }
                <Button href="/" className="back-button">
                    <span> {props.getLangString('errorHandling.reloadStartPage')} </span>
                </Button >
            </Container>
        </div>
    )
}

export default ErrorBanner;