import React from 'react'
import Grid from '@material-ui/core/Grid';
import { ToggleMenuButton } from './ToggleMenuButton'

export interface IFilterToggleProps {
    getLangString: (value: string) => string;
    showingMenu: boolean
    onClick: (e?: Event) => void
}

export const FilterToggle = (props: IFilterToggleProps): JSX.Element => {

    return (
        <div className="filter-toggle" role="complementary">
            <Grid container spacing={0} className="filter-toggle-container">
                <Grid item xs={2} sm={2} md={2} lg={2} xl={2} className="filter-toggle-item filter-panel-toggle-item">
                    <ToggleMenuButton getLangString={props.getLangString} showingMenu={props.showingMenu} onClick={() => { props.onClick() }} />
                </Grid>
            </Grid>
        </div>
    )
}