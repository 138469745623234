import { ThunkAction } from 'redux-thunk'
import { RootState } from '../RootReducer';
import { Action } from 'redux';
import * as BreadcrumbsActionTypes from './ActionTypes'

export const setTitle = (title: string): ThunkAction<void, RootState, unknown, Action<string>> => async dispatch => {
    try {
        dispatch({
            type: BreadcrumbsActionTypes.SET_TITLE,
            payload: title
        });

    } catch (error) {
        console.error(error);
        dispatch({ type: BreadcrumbsActionTypes.SET_TITLE_FAILURE, payload: error })
    }
}

export const clearTitle = (): ThunkAction<void, RootState, unknown, Action<string>> => async dispatch => {
    try {
        dispatch({
            type: BreadcrumbsActionTypes.CLEAR_TITLE
        });

    } catch (error) {
        console.error(error);
        dispatch({ type: BreadcrumbsActionTypes.CLEAR_TITLE_FAILURE, payload: error })
    }
}