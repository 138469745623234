import { IAppState, AppStateActionTypes, SET_ERROR, SET_ERROR_FAILURE, CLEAR_ERROR, CLEAR_ERROR_FAILURE } from "./ActionTypes";

const initialState: IAppState = {
    errorSummary: {
        isError: false,
        errorMessage: '',
        errors: []
    }
};

const appStateReducer = (state = initialState, action: AppStateActionTypes): IAppState => {
    switch (action.type) {
        case SET_ERROR:
            return {
                ...state,
                errorSummary: {
                    isError: true,
                    errorMessage: action.payload.errorMessage,
                    errors: [...state.errorSummary.errors, action.payload.error]
                }
            }

        case SET_ERROR_FAILURE:
            return {
                ...state,
                error: action.payload
            }

        case CLEAR_ERROR:
            return {
                ...state,
                errorSummary: {
                    isError: false,
                    errorMessage: '',
                    errors: [...state.errorSummary.errors]
                }
            }

        case CLEAR_ERROR_FAILURE:
            return {
                ...state,
                error: action.payload
            }

        default:
            return state;
    }
}

export default appStateReducer;