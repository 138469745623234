
import React from "react";
import { useTranslation } from 'react-i18next';
import { Link } from "react-router-dom";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faChevronRight } from '@fortawesome/free-solid-svg-icons'
import './ThemeBreadcrumbs.scss';
import { Container } from "@material-ui/core";
import { useSelector } from "react-redux";
import { Title } from '../../store/Breadcrumbs/Selector';


export interface IBreadcrumbsProps {
    breadcrumbs: IBreadCrumb[];
}

export interface IBreadCrumb {
    path: string;
    name: string;
}

export const Breadcrumbs = (props: IBreadcrumbsProps): JSX.Element | null => {
    const title = useSelector(Title);
    const { t } = useTranslation('common');
    if (props.breadcrumbs.length <= 1) {
        return null;
    }

    return (
        <Container>
            <div className="breadcrumbs-wrapper" role="complementary">
                {props.breadcrumbs.map(({ name, path }: IBreadCrumb, key: number) =>
                    key + 1 === props.breadcrumbs.length ? (
                        <span key={key} className="breadcrumb-last">
                            {title ? title :  t(name)}
                        </span>
                    ) : (
                        <React.Fragment key={key}>
                            <Link className="breadcrumbs-link" to={path}>
                                {t(name)}
                            </Link>

                            <FontAwesomeIcon
                                icon={faChevronRight}
                                className="breadcrumbs-icon"
                                size="xs"
                            />
                        </React.Fragment>
                    )
                )}
            </div>
        </Container>
    )
}

export default Breadcrumbs;