import React, { useEffect, useState } from "react";
import { IMarker, IVehicle } from "../../../../store/Markers/Marker";
import '../ThemeMarker.scss';
import { MarkerIsLiveHours } from "../../../../store/Settings/Selector";
import { useSelector } from "react-redux";
import { MarkerIsLive } from "../../../../utils/helpers/MarkerHelper";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCircle } from "@fortawesome/free-solid-svg-icons";
import { vehicles } from "../../../../constants/VehicleConstants";

interface IVehicleProps {
    active: boolean;
    getLangString: (value: string) => string;
    marker: IMarker;
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    onClick: (e?: any) => void;
    typeOfVehicle: string;
    color: string;
}


export const Vehicle = (props: IVehicleProps): JSX.Element => {

    const markerIsLiveHours = useSelector(MarkerIsLiveHours);
    const [markerIsLive, setMarkerIsLive] = useState<boolean>(false);
    const [vehicle, setVehicle] = useState<IVehicle | null>(null);
    const [lng, setLng] = useState<number>(0);
    const [movingEast, setMovingEast] = useState<boolean>(false);

    useEffect(() => {
        setVehicle(vehicles.find((vehicle) => vehicle.type === props.typeOfVehicle) || null);
    }, [props.typeOfVehicle]);

    useEffect(() => {
        setMarkerIsLive(MarkerIsLive(props.marker, markerIsLiveHours, false));
    }, [props.marker]);

    useEffect(() => {
        if (props.marker.lng !== lng) {
            if (props.marker.lng && (props.marker.lng > lng)) {
                setMovingEast(true);
            } else if (props.marker.lng && (props.marker.lng < lng)) {
                setMovingEast(false);
            }

            setLng(props.marker.lng ?? 0);
        }
    }, [props.marker.lng]);

    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    const handleClickOnVehicle = (e: any) => {
        if (e?.key === 'Enter') {
            props.onClick(e);
        }
    }

    return (
        <React.Fragment>
            <div
                tabIndex={0}
                className={'marker vehicle ' + (vehicle?.color ?? '') + (props.active ? ' active' : '') + (markerIsLive ? ' live' : '')}
                onClick={(event) => props.onClick(event)}
                onKeyDown={handleClickOnVehicle}
            >
                <div className="icon-container">
                    <img
                        src={vehicle?.image}
                        className={`img-vehicle ${movingEast ? '' : 'left'}`}
                        onClick={(event) => props.onClick(event)}
                    />
                </div>
                {markerIsLive &&
                    <div className="live-pin" role="complementary">
                        <FontAwesomeIcon icon={faCircle} className="circle" />
                    </div>
                }
            </div>
        </React.Fragment>
    );
};