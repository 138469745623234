import React, { useEffect, useRef, useState } from 'react';
import { ICategory } from '../../../store/Events/Event';
import { Button } from '@material-ui/core';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faChevronDown } from '@fortawesome/free-solid-svg-icons';
import { CategoryFilterItem } from './CategoryFilterItem';
import useDeviceDetect from '../../../utils/hooks/DeviceDetect';

export interface ICategoryFilterProps {
    categories: ICategory[];
    selectedCategories?: ICategory[];
    onChangeCategory: (categories: ICategory[]) => void;
    getLangString: (value: string) => string;
}

export const CategoryFilter = (props: ICategoryFilterProps): JSX.Element => {
    const [selectedCategories, setSelectedCategories] = useState<ICategory[]>(props.selectedCategories ?? []);
    const [menuOpen, setMenuOpen] = useState(false);
    const refCategoryFilter = useRef<HTMLDivElement>(null);
    const { isMobile } = useDeviceDetect();

    const handleClickFilter = (categories: ICategory[]) => {
        props.onChangeCategory(categories);       
    }

    const handleClickItem = (category: ICategory) => {
        if (selectedCategories.some(c => c.id === category.id)) {
            setSelectedCategories(selectedCategories.filter(c => c.id !== category.id));
        } else {
            setSelectedCategories(selectedCategories.concat([category]));
        }
        
    }
    useEffect(()=>{
        handleClickFilter(selectedCategories);
    }, [selectedCategories]);

    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    const handleClickRef = (e: { target: any }) => {
        if (refCategoryFilter.current && !refCategoryFilter.current?.contains(e.target)) {
            setMenuOpen(false);
        }
    }
    useEffect(() => {
        setSelectedCategories(props.selectedCategories ?? []);
    }, [props.selectedCategories]);

    useEffect(() => {
        document.addEventListener("mousedown", handleClickRef);
        return () => {
            document.removeEventListener("mousedown", handleClickRef);
        };
    }, []);

    return (
        <div ref={refCategoryFilter} className={`select category ${menuOpen ? 'open' : 'closed'}`}>
            <Button onClick={() => setMenuOpen(!menuOpen)}>
                {(isMobile || menuOpen || (selectedCategories && selectedCategories.length === 0)) ?
                    props.getLangString('event.filter.selectCategories') :
                    (!isMobile && selectedCategories && selectedCategories.length === 1) ?
                        selectedCategories[0].title :
                        selectedCategories?.length + props.getLangString('event.filter.categoriesPicked')
                }

                <FontAwesomeIcon icon={faChevronDown} className={`chevron-down ${menuOpen ? 'open' : ''}`} />
            </Button>
            {menuOpen &&
                <React.Fragment>
                    <hr className="solid" />
                    {props.categories && props.categories.map((c: ICategory, i: number) => (
                        <CategoryFilterItem
                            key={i}
                            category={c}
                            active={selectedCategories.some(cat => cat.id === c.id) ? true : false}
                            onClick={(category) => handleClickItem(category)}
                        />
                    ))}

                    
                </React.Fragment>
            }
        </div>
    );
}
