export interface IMarker {
    title: string;
    id: string;
    lat?: number;
    lng?: number;
    address?: IAddress;
    description: string;
    link: string;
    types: IMarkerType[];
    subTypes?: IMarkerType[];
    markerShapes: IMarkerShape[];
    imageUrl: string;
    detailsUrl: string;
    bookingLink?: string;
    deleted: boolean;
    published: boolean;
    source: MarkerSource;
    dynamicContent?: IDynamicContent;
    modified: Date;
    siblings?: IMarkerSibling[];
    icon?: string;
}

export interface IMarkerSibling {
    title: string;
    id: string;
    address?: IAddress;
    description: string;
    link: string;
    types: IMarkerType[];
    subTypes?: IMarkerType[];
    markerShapes: IMarkerShape[];
    imageUrl: string;
    detailsUrl: string;
    source: MarkerSource;
    dynamicContent?: IDynamicContent;
    bookingLink?: string;
}

export interface IInfoBox {
    title: string;
    id: string;
    description: string;
    imageUrl: string;
    address?: IAddress;
    detailsUrl: string;
    bookingLink?: string;
    dynamicContent?: IDynamicContent;
    subTypes?: IMarkerType[];
    source: MarkerSource;
}

export interface IMarkerType {
    title: string;
    name: string;
    icon: string;
    class: string;
}

export interface IMarkerShape {
    type: string;
    class: string;
    vertices: IVertices[];
}

export interface IVertices {
    lat: number;
    lng: number;
}

export interface IDynamicContent {
    numberOfColumns: number;
    dividerBetweenColumns: boolean;
    dividerBetweenRows: boolean;
    properties: IProperty[];
}

export interface IProperty {
    label: string;
    icon: string;
    link: string;
    value: number;
    class: string;
}

export interface IAddress {
    street: string;
    zip: string;
    city: string;
}

export interface IPolyline {
    id: string;
    color: string;
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    polylines: any[];
}

export enum MarkerSource {
    Naturkartan,
    Iot,
    Event,
    Trafikverket,
    CityBreak,
    DataScraping,
    SvenskaLag,
    GIS,
    Hjartstartarregistret,
    Other
}

export type VehicleType = 'fyrskar' | 'karoline' | 'astrid' | 'train' | 'bus';

export interface IVehicle {
    name: string;
    type: VehicleType;
    image: string;
    color: string;
}