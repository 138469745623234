import React from 'react';
import logoRonneby from '../../images/ronneby/ronneby-kommun.svg';
import logoOlofstrom from '../../images/olofstrom/olofstrom_kommun.png';
import { THEME_KEY } from '../../Constants';

export function GetImageLogo(): JSX.Element | null {
  switch (THEME_KEY) {
    case 'ronneby':
      return <img src={logoRonneby} alt="Ronneby kommun" />;
    case 'olofstrom':
      return <img src={logoOlofstrom} alt="Olofström kommun " />;
    default:
      return null;
  }
}
export function GetDefaultMapImage(): string {
    return `${process.env.PUBLIC_URL}/images/${THEME_KEY}/map.png`;
}