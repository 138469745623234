import React, { KeyboardEvent } from "react";
import { ICategory } from "../../../store/Events/Event";

interface ICategoryFilterItemProps {
    category: ICategory;
    active: boolean;
    onClick: (category: ICategory) => void;
}

export const CategoryFilterItem = (props: ICategoryFilterItemProps): JSX.Element => {
    const onKeyUp = (e: KeyboardEvent<HTMLInputElement>, c: ICategory) => {
        if (e.keyCode === 13) {
            props.onClick(c);
        }
    }

    return (
        <div
            tabIndex={0}
            className={`category-picker-item ${props.active ? 'active' : ''} `}
            onClick={() => props.onClick(props.category)}
            onKeyUp={(event: KeyboardEvent<HTMLInputElement>) => onKeyUp(event, props.category)}
        >
            {props.category.title}
        </div>
    );
}
