import { ICategory, IEvent, IEventResponse } from "./Event";

export const GET_EVENTS_BEGIN = 'GET_EVENTS_BEGIN';
export const GET_EVENTS_SUCCESS = 'GET_EVENTS_SUCCESS';
export const GET_EVENTS_FAILURE = 'GET_EVENTS_FAILURE';
export const SET_FILTERED_EVENTS = 'SET_FILTERED_EVENTS';
export const SET_FILTERED_EVENTS_FAILURE = 'SET_FILTERED_EVENTS_FAILURE';
export const SET_EVENTS_PAGE_SCROLL_POSITION = 'SET_EVENTS_PAGE_SCROLL_POSITION';
export const SET_EVENTS_PAGE_SCROLL_POSITION_FAILURE = 'SET_EVENTS_PAGE_SCROLL_POSITION_FAILURE';
export const SET_EVENTS_PAGE_FILTERS = 'SET_EVENTS_PAGE_FILTERS';
export const SET_EVENTS_PAGE_FILTERS_FAILURE = 'SET_EVENTS_PAGE_FILTERS_FAILURE';
export const CLEAR_EVENTS_PAGE_FILTERS = 'CLEAR_EVENTS_PAGE_FILTERS';
export const CLEAR_EVENTS_PAGE_FILTERS_FAILURE = 'CLEAR_EVENTS_PAGE_FILTERS_FAILURE';

export interface IEventState {
    eventList: IEvent[],
    filteredEventList: IEvent[],
    categoryList: ICategory[],
    loading: boolean,
    error?: string,
    eventsPageState: {
        scrollPosition: number;
        filters?: IEventFilters
    };
}

export interface IEventFilters {
    date?: Date;
    categories?: ICategory[];
    text?: string;
}

interface GetEventsBeginAction {
    type: typeof GET_EVENTS_BEGIN
}

interface GetEventsSuccessAction {
    type: typeof GET_EVENTS_SUCCESS,
    payload: IEventResponse
}

interface GetEventsFailure {
    type: typeof GET_EVENTS_FAILURE,
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    payload: any
}

interface SetFilteredEvents {
    type: typeof SET_FILTERED_EVENTS,
    payload: IEvent[]
}

interface SetFilteredEventsFailure {
    type: typeof SET_FILTERED_EVENTS_FAILURE,
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    payload: any
}


interface SetEventsPageScrollPosition {
    type: typeof SET_EVENTS_PAGE_SCROLL_POSITION,
    payload: number;
}

interface SetEventsPageScrollPositionFailure {
    type: typeof SET_EVENTS_PAGE_SCROLL_POSITION_FAILURE,
    payload: string
}

interface SetEventsPageFilters {
    type: typeof SET_EVENTS_PAGE_FILTERS,
    payload: IEventFilters;
}

interface SetEventsPageFiltersFailure {
    type: typeof SET_EVENTS_PAGE_FILTERS_FAILURE,
    payload: string
}

interface ClearEventsPageFilters {
    type: typeof CLEAR_EVENTS_PAGE_FILTERS,
}

interface ClearEventsPageFiltersFailure {
    type: typeof CLEAR_EVENTS_PAGE_FILTERS_FAILURE,
    payload: string
}

export type EventActionTypes = GetEventsBeginAction |
    GetEventsSuccessAction |
    GetEventsFailure |
    SetFilteredEvents |
    SetFilteredEventsFailure |
    SetEventsPageScrollPosition |
    SetEventsPageScrollPositionFailure |
    SetEventsPageFilters |
    SetEventsPageFiltersFailure |
    ClearEventsPageFilters |
    ClearEventsPageFiltersFailure;