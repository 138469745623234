import moment from 'moment';
import 'moment/locale/sv';
import React, { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux';
import { useParams } from 'react-router-dom';
import { ICategory, IEvent } from '../../store/Events/Event';
import { Events, EventsLoading } from '../../store/Events/Selector';
import defaultImage from '../../images/default-img.gif';
import './ThemeEvent.scss';
import { getEvents } from '../../store/Events/Actions';
import { Button, Grid, Hidden, Link } from '@material-ui/core';
import { DateList } from '../common/datepicker/DateList';
import useDeviceDetect from '../../utils/hooks/DeviceDetect';
import { setTitle, clearTitle } from '../../store/Breadcrumbs/Actions';
import { Share } from '../common/share/Share';
import { SEO } from '../common/seo/SEO';
import ReactMarkdown from 'react-markdown';
import rehypeRaw from 'rehype-raw';

interface ParamTypes {
    id: string,
    start: string
}

export interface IEventDetailsProps {
    getLangString: (value: string) => string;
}

export const EventDetails = (props: IEventDetailsProps): JSX.Element => {
    const { id, start } = useParams<ParamTypes>();
    const events = useSelector(Events);
    const eventsLoading = useSelector(EventsLoading);
    const [event, setEvent] = useState<IEvent | undefined>();
    const [relatedEvents, setRelatedEvents] = useState<IEvent[] | undefined>();
    const [dateList, setDateList] = React.useState(false);

    const { isMobile } = useDeviceDetect();

    const dispatch = useDispatch();

    useEffect(() => {
        if ((!events || events.length === 0) && !eventsLoading) {
            dispatch(getEvents());
        }
        window.scrollTo(0, 0);

    }, [dispatch]);

    useEffect(() => {
        const fixedId = id.replaceAll('_', ':');
        const fixedStart = start.replaceAll('_', ':');
        const eventId = decodeURIComponent(fixedId);
        const startDate = new Date(decodeURIComponent(fixedStart));
        const eventToShow = events.find(e => e.id === eventId && new Date(e.start).getTime() === startDate.getTime());
        if (eventToShow != null) {
            setEvent(eventToShow);
        }
        // Related events
        const relatedEvents = events.filter((e) => e.id === eventId);
        if (relatedEvents != null) {
            setRelatedEvents(relatedEvents);
        }

    }, [events, id, start, event]);

    useEffect(() => {
        if (event) {
            dispatch(setTitle(event?.title));
        }

        return function cleanup() {
            dispatch(clearTitle());
        }
    }, [event]);

    return (
        <div className="event-details-wrapper" role="article">
            {event &&
                <SEO title={`${event?.title} |  ${props.getLangString('global.pageTitle')}`} description={event?.description} imageUrl={event?.imageUrl} />
            }

            <Grid container direction="row">
                <Grid item xs={12}>
                    <div className="event-date-container">
                        <div className="event-date">
                            {/* {new Intl.DateTimeFormat("sv", dateFormatOptions).format(props.event.start)} */}
                            {moment(event?.start).format('D MMMM')}
                        </div>
                        <div className="event-date">
                            {moment(event?.start).format('HH:mm') !== '00:00' &&
                                <React.Fragment>
                                    {moment(event?.start).format('HH:mm')} - {moment(event?.end).format('HH:mm') !== '00:00' && moment(event?.end).format('HH:mm')}
                                </React.Fragment>
                            }
                        </div>
                    </div>
                    <Grid item xs={12} md={8}>
                        <h1 className="event-title">{event?.title}</h1>
                    </Grid>

                    <Hidden mdUp={true}>
                        <div className="event-image">
                            <img src={event?.imageUrl == null ? defaultImage : event?.imageUrl} alt={`${props.getLangString('global.image')} ${event?.title}`} className="img-fluid" />
                        </div>
                    </Hidden>
                </Grid>
            </Grid>

            <Grid container direction="row">
                <Grid item xs={12} md={8} className="event-main">
                    <div className="event-ingress">
                        {event?.ingress}
                    </div>
                    {event && 
                        <ReactMarkdown rehypePlugins={[rehypeRaw]} className="event-description">
                            {event?.description}
                        </ReactMarkdown>
                    }
                    <Hidden mdDown={true}>
                        <div className="event-image">
                            <img src={event?.imageUrl == null ? defaultImage : event?.imageUrl} alt={`${props.getLangString('global.image')} ${event?.title}`} className="img-fluid" />
                        </div>
                    </Hidden>
                </Grid>

                <Grid item xs={12} md={4} className="event-tile">
                    {isMobile &&
                        <Grid item xs={12} className="hr-grid-item">
                            <div className="horizontalRow">
                                <hr></hr>
                            </div>
                        </Grid>
                    }
                    <h3 className="sub-title main">{props.getLangString('event.dateAndTime')}</h3>
                    <h4 className="event-details-title">{props.getLangString('global.date')}</h4>
                    <div className="event-details"> {moment(event?.start).format('D MMMM YYYY')}</div>
                    {moment(event?.start).format('HH:mm') !== '00:00' &&
                        <React.Fragment>
                            <h4 className="event-details-title">{props.getLangString('global.time')}</h4>
                            <div className="event-details">
                                {moment(event?.start).format('HH:mm')} - {moment(event?.end).format('HH:mm') !== '00:00' && moment(event?.end).format('HH:mm')}
                            </div>
                        </React.Fragment>
                    }
                    {event?.location &&
                        <React.Fragment>
                            <h4 className="event-details-title">{props.getLangString('event.location')}</h4>
                            <div className="event-details">
                                {event?.location.name}
                            </div>
                        </React.Fragment>
                    }
                    {event?.categories && event?.categories.length > 0 &&
                        <React.Fragment>
                            <h4 className="event-details-title">{props.getLangString('event.category')}</h4>
                            <div className="event-details">
                                {event?.categories.map((category: ICategory, index: number) =>
                                    <React.Fragment key={index}>
                                        {category.title}
                                        <div className={`${event?.categories && event?.categories.length - 1 === index ? 'hide' : 'inline'}`}>
                                            {', '}
                                        </div>
                                    </React.Fragment>
                                )}
                            </div>
                        </React.Fragment>
                    }
                    {relatedEvents && relatedEvents.length > 1 &&
                        <React.Fragment>
                            <h4 className="event-details-title">{props.getLangString('event.furtherDates')}</h4>
                            <button className="day-picker-btn" onClick={() => setDateList(!dateList)}><span className="day-picker-btn-text">{`${dateList ? props.getLangString('event.hideDates') : props.getLangString('event.showOtherDates')}`}</span></button>
                            {dateList &&
                                <DateList getLangString={props.getLangString} relatedEvents={relatedEvents} eventStart={event?.start ? event.start : new Date()} />
                            }
                        </React.Fragment>
                    }

                    {(event?.phoneNumber || event?.email) && isMobile &&
                        <Grid item xs={12} className="hr-grid-item">
                            <div className="horizontalRow">
                                <hr></hr>
                            </div>
                        </Grid>
                    }

                    {(event?.phoneNumber || event?.email) &&
                        <h3 className="sub-title">{props.getLangString('event.contactInformation')}</h3>
                    }
                    {event?.phoneNumber &&
                        <React.Fragment>
                            <h4 className="event-details-title">{props.getLangString('event.phoneNumber')}</h4>
                            <div className="event-details">
                                <a href={`tel: ${event?.phoneNumber}`} className={'contact_info_link'}>{event?.phoneNumber}</a>
                            </div>
                        </React.Fragment>
                    }
                    {event?.email &&
                        <React.Fragment>
                            <h4 className="event-details-title">{props.getLangString('event.email')}</h4>
                            <div className="event-details breakline">
                                <a href={`mailto: ${event?.email}`} className={'contact_info_link'}>{event?.email}</a>
                            </div>
                        </React.Fragment>
                    }

                    {event?.webpage && isMobile &&
                        <Grid item xs={12} className="hr-grid-item">
                            <div className="horizontalRow">
                                <hr></hr>
                            </div>
                        </Grid>
                    }

                    {event?.webpage &&
                        <React.Fragment>
                            <h3 className="sub-title">{props.getLangString('event.links')}</h3>
                            <h4 className="event-details-title">{props.getLangString('event.webpage')}</h4>
                            <div className="event-details breakline">
                                <Link href={event?.webpage} target="_blank" rel="noopener">{event?.webpage}</Link>
                            </div>
                        </React.Fragment>
                    }
                    {event?.bookingLink &&
                        <React.Fragment>
                            <div className="event-details breakline">
                                <Button href={event?.bookingLink} target="_blank" className="booking-link">
                                    <span>
                                        {props.getLangString('map.bookTicket')}
                                    </span>
                                </Button>
                            </div>
                        </React.Fragment>
                    }

                    {event?.title &&
                        <Share title={event?.title} getLangString={props.getLangString} />
                    }

                </Grid>
            </Grid>
        </div>
    )
}
