/* eslint-disable @typescript-eslint/no-explicit-any */
import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useHistory } from "react-router-dom";
import { setClickedOnPolyline, setMarkerDialogAsOpen, updatePolylines } from "../../../store/Markers/Actions";
import { IPolyline, IVertices } from "../../../store/Markers/Marker";
import { Polylines } from "../../../store/Markers/Selector";
import { isNullEmptyOrUndefined } from "../../../utils/helpers/ObjectHelper";
import { vehicles } from "../../../constants/VehicleConstants";
interface IPolylineProps {
    listOfVertices: IVertices[][];
    map: any;
    maps: any;
    id: string;
    color: string;
}

export const Polyline = (props: IPolylineProps): JSX.Element => {
    const { listOfVertices, map, maps, id, color } = props;
    const polylines = useSelector(Polylines);
    
    const dispatch = useDispatch();
    const history = useHistory();

    const handleClickOnPolyline = () => {
        if (vehicles.some(v => id.includes(v.name))) {
            const params = new URLSearchParams();
            dispatch(setClickedOnPolyline(true));
            dispatch(setMarkerDialogAsOpen(id));
            params.append('pin', id);
            history.push({ search: params.toString() })

        } else {
            dispatch(setClickedOnPolyline(false));
        }
    };

    const handleClickOnMap = () => {
        dispatch(setClickedOnPolyline(false));
    }

    useEffect(() => {
        if (map && maps && id && listOfVertices && color && polylines) {
            if (!polylines.some(polyline => polyline.id === id) && !isNullEmptyOrUndefined(id)) {
                const newPolyline: IPolyline = {
                    id: id,
                    polylines: [],
                    color: color
                };
                listOfVertices.forEach(vertice => {
                    const geodesicPolyline = addNewPolyline(vertice);
                    if (!isNullEmptyOrUndefined(geodesicPolyline)) {
                        newPolyline.polylines.push(geodesicPolyline);
                    }
                });

                polylines.push(newPolyline);
                dispatch(updatePolylines(polylines));

            }
        }
    }, [id, listOfVertices]);

    const addNewPolyline = (vertices: IVertices[]): any[] => {
        let geodesicPolyline = maps.Polyline;
        if (vertices.length > 0) {
            const lineSymbol = {
                path: "M 0,0 0,1",
                strokeOpacity: 1,
                scale: 3,
            };
            geodesicPolyline = new maps.Polyline({
                key: id,
                path: vertices,
                geodesic: true,
                strokeColor: props.color,
                strokeOpacity: 0,
                strokeWeight: 4,
                icons: [
                    {
                        icon: lineSymbol,
                        offset: "0",
                        repeat: "10px",
                    },
                ],
            });

            geodesicPolyline.setMap(map);
            geodesicPolyline.addListener("click", handleClickOnPolyline);
            map.addListener("click", handleClickOnMap);
        }

        return geodesicPolyline;
    }

    return (
        <React.Fragment />
    );
};

