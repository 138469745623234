import React from 'react'
import { RichText, RichTextBlock } from 'prismic-reactjs'
import { LinkResolver } from '../../../utils/prismic/LinkResolver';
import './PrismicRichText.scss';

export interface IPrismicRichTextProps {
    render: RichTextBlock[]
}
export const PrismicRichText = (props: IPrismicRichTextProps): JSX.Element => {
    return (
        <div className="prismic">
            <RichText render={props.render} linkResolver={LinkResolver} />
        </div>
    );
}