import React from 'react';
import { Grid, Container } from '@material-ui/core';
import Prismic from '@prismicio/client'
import { RichText } from 'prismic-reactjs'
import { Document } from '@prismicio/client/types/documents';
import { LinkResolver } from '../utils/prismic/LinkResolver';
import { PRISMIC_URL, PRISMIC_TOKEN } from '../Constants';

export const InternetExplorer = (): JSX.Element => {
    const client = Prismic.client(PRISMIC_URL, { accessToken: PRISMIC_TOKEN })
    const [doc, setDocData] = React.useState<Document | undefined>()

    React.useEffect(() => {
        const fetchData = async () => {
            const doc = await client.getSingle('notsuported', {});
            setDocData(doc);
        }
        fetchData()
    }, []);

    return (
        <React.Fragment>
            { doc &&

                <Container role="main">
                    <div className="not-found-wrapper" role="article">
                        <Grid container direction="row">
                            <Grid item xs={12}>
                                <h1>{RichText.asText(doc.data.title)}</h1>

                                <RichText render={doc.data.body} linkResolver={LinkResolver} />
                            </Grid>
                        </Grid>
                    </div>
                </Container>
            }
        </React.Fragment>
    );
}