import axios from 'axios';
import { API_ROOT } from '../../Constants';
import { IFilter } from "./Filter";
import { ThunkAction } from 'redux-thunk'
import { RootState } from '../RootReducer';
import { Action } from 'redux';
import * as FilterActions from './ActionTypes'

// Fetch all filters from backend 
export const getFilters = (): ThunkAction<void, RootState, unknown, Action<string>> => async dispatch => {
    try {
        dispatch({ type: FilterActions.GET_FILTERS_BEGIN });

        const result = await axios.get(`${API_ROOT}/filter`);
        dispatch({
            type: FilterActions.GET_FILTERS_SUCCESS,
            payload: result.data
        });
    } catch (error) {
        console.error(error);
        dispatch({ type: FilterActions.GET_FILTERS_FAILURE, payload: error })
    }
} 

export const updateSelectedFilters = (selectedFilters: IFilter[]): ThunkAction<void, RootState, unknown, Action<string>> => async dispatch => {
    try {
        dispatch({ 
            type: FilterActions.UPDATE_SELECTED_FILTERS,
            payload: selectedFilters
        });

    } catch (error) {
        console.error(error);
        dispatch({ type: FilterActions.UPDATE_SELECTED_FILTERS_FAILURE, payload: error })
    }
}

export const cleanSelectedFilters = (): ThunkAction<void, RootState, unknown, Action<string>> => async dispatch => {
    try {
        dispatch({ 
            type: FilterActions.CLEAN_SELECTED_FILTERS,
        });

    } catch (error) {
        console.error(error);
    }
}

export const setSelectedFilterTitle = (title: string): ThunkAction<void, RootState, unknown, Action<string>> => async dispatch => {
    dispatch({ type: FilterActions.SET_SELECTED_FILTER_TITLE, payload: title });
}





