import { SettingsActionTypes, GET_SETTINGS_BEGIN, GET_SETTINGS_FAILURE, GET_SETTINGS_SUCCESS, ISettingsState } from './ActionTypes';

const initialState: ISettingsState = {
    defaultFilters: [],
    defaultEventCategories: [],
    markerIsLiveHours: 0,
    loading: false,
};

const settingsReducer = (state = initialState, action: SettingsActionTypes): ISettingsState => {
    switch (action.type) {
        case GET_SETTINGS_BEGIN:
            return {
                ...state,
                loading: true
            };

        case GET_SETTINGS_SUCCESS:
            return {
                ...state,
                loading: false,
                defaultFilters: action.payload.defaultFilters,
                defaultEventCategories: action.payload.defaultEventCategories,
                markerIsLiveHours: action.payload.markerIsLiveHours
            }

        case GET_SETTINGS_FAILURE:
            return {
                ...state,
                loading: false,
                error: action.payload
            }

        default:
            return state;
    }
}

export default settingsReducer;
