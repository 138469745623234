import React, { useCallback, useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { CategoryFilter } from './filters/CategoryFilter';
import { DateFilter } from './filters/DateFilter';
import { SearchBar } from './filters/SearchBar';
import { Button, Grid, Hidden, Paper } from "@material-ui/core";
import { Categories, Events } from "../../store/Events/Selector";
import { ICategory, IEvent } from "../../store/Events/Event";
import { setFilteredEvents } from "../../store/Events/Actions";
import useDeviceDetect from '../../utils/hooks/DeviceDetect';
import { faSortAmountDownAlt, faTimes } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { FilterItem } from './filters/FilterItem';
import './ThemeEventFilter.scss';
import { EventsPageFilters } from "../../store/Events/Selector";
import { setEventsPageFilters } from "../../store/Events/Actions";

export interface IEventFilterProps {
    getLangString: (value: string) => string;
}

export const EventFilter = (props: IEventFilterProps): JSX.Element => {
    const eventsPageFilters = useSelector(EventsPageFilters);
    const categories = useSelector(Categories);
    const events = useSelector(Events);
    const [filterText, setFilterText] = useState(eventsPageFilters?.text ?? '');
    const [selectedDate, setSelectedDate] = useState<Date | null>(eventsPageFilters?.date ?? null);
    const [selectedCategories, setSelectedCategories] = useState<ICategory[]>(eventsPageFilters?.categories ?? []);
    const [mobileMenuOpen, setMobileMenuOpen] = useState(false);
    const { isMobile } = useDeviceDetect();
    const dispatch = useDispatch();

    const search = useCallback(() => {

        const handleTextFilter = (eventsToFilter: IEvent[]) => {
            return eventsToFilter.filter((event) => JSON.stringify(event).toLowerCase().includes(filterText.toLowerCase()));
        }

        const handleCategoryFilter = (eventsToFilter: IEvent[]) => {
            return eventsToFilter.filter((event: IEvent) => {
                return event.categories.some((eCategory: ICategory) => {
                    return selectedCategories.some((category: ICategory) => {
                        return eCategory.id === category.id;
                    });
                });
            });
        }

        const handleDateFilter = (eventsToFilter: IEvent[]) => {
            if (!selectedDate) {
                return eventsToFilter;
            }
            return eventsToFilter.filter((event) => new Date(event.start).setHours(0, 0, 0, 0) <= selectedDate.setHours(0, 0, 0, 0));
        }

        let eventsToFilter: IEvent[] = events;

        if (filterText.length > 0) {
            eventsToFilter = handleTextFilter(eventsToFilter);
        }
        if (selectedCategories.length > 0) {
            eventsToFilter = handleCategoryFilter(eventsToFilter);
        }
        if (selectedDate) {
            eventsToFilter = handleDateFilter(eventsToFilter);
        }

        if (eventsPageFilters?.date !== selectedDate || eventsPageFilters?.categories !== selectedCategories || eventsPageFilters?.text !== filterText) {
            dispatch(setEventsPageFilters({ date: selectedDate ?? undefined, categories: selectedCategories, text: filterText }));
        }
        dispatch(setFilteredEvents(eventsToFilter));

    }, [dispatch, events, filterText, selectedCategories, selectedDate, isMobile, eventsPageFilters])

    const searchOnMobile = () => {
        search();
        setMobileMenuOpen(false);
        window.scrollTo({ top: 0 });
    }

    useEffect(() => {
        if (!isMobile) {
            search();
        }
    }, [isMobile, selectedCategories, selectedDate])

    useEffect(() => {
        search();
    }, [events, filterText])

    return (
        <React.Fragment>
            <Hidden mdDown>
                <Paper className="event-filter">
                    <Grid container direction="row">
                        <Grid item xs={8} lg={7} xl={6} className="select-container">
                            <DateFilter date={new Date()} selectedDate={selectedDate ?? undefined} onChangeDate={setSelectedDate} getLangString={props.getLangString} />
                            <CategoryFilter categories={categories} selectedCategories={selectedCategories} onChangeCategory={setSelectedCategories} getLangString={props.getLangString} />
                        </Grid>
                        <Grid item xs={4} lg={5} xl={6} className="search-container">
                            <SearchBar filterText={filterText} onChangeText={setFilterText} getLangString={props.getLangString} />
                        </Grid>
                    </Grid>
                </Paper >
            </Hidden>

            <Hidden mdUp>
                <Paper className="event-filter">
                    {mobileMenuOpen &&
                        <div className="overlay">
                            <div className="filter-menu-container">
                                <Grid container className="filter-menu">
                                    <Grid item xs={12} className="select-container">
                                        <DateFilter date={new Date()} selectedDate={selectedDate ?? undefined} onChangeDate={setSelectedDate} getLangString={props.getLangString} />
                                        <CategoryFilter categories={categories} selectedCategories={selectedCategories} onChangeCategory={setSelectedCategories} getLangString={props.getLangString} />

                                        <div className="picked-categories">
                                            {selectedCategories && selectedCategories.map((category: ICategory, index: number) =>
                                                <FilterItem key={index} title={category.title} id={category.id} onDelete={(id) => setSelectedCategories(selectedCategories.filter(c => c.id !== id))} />
                                            )}
                                        </div>
                                    </Grid>
                                    <Grid item xs={12} className="filter-btn-container">
                                        <Button onClick={() => searchOnMobile()} className="filter-menu-btn filter-btn primary-btn">{props.getLangString('event.filter.searchWithFilter')}</Button>
                                    </Grid>
                                </Grid>
                            </div>
                        </div>
                    }

                    <div className="search-container">
                        <SearchBar filterText={filterText} onChangeText={setFilterText} getLangString={props.getLangString} />
                    </div>

                    <div className="filter-btn-container">
                        {mobileMenuOpen ?
                            <Button className="filter-btn icon-btn" onClick={() => setMobileMenuOpen(!mobileMenuOpen)}>
                                <FontAwesomeIcon icon={faTimes} className="icon" />
                                <span> {props.getLangString('event.filter.hideFilter')} </span>
                            </Button >
                            :
                            <Button className="filter-btn icon-btn" onClick={() => setMobileMenuOpen(!mobileMenuOpen)}>
                                <FontAwesomeIcon icon={faSortAmountDownAlt} className="icon" />
                                <span> {props.getLangString('event.filter.showFilter')} </span>
                            </Button >
                        }
                    </div>
                </Paper>
            </Hidden>
        </React.Fragment>
    );
}