/* eslint-disable react/prop-types */
import React, { Suspense, useEffect } from 'react';
import './style/ThemeApp.scss';
import { TopNavigation } from './components/navigation/TopNavigation';
import { NotFound } from './pages/NotFound';
import { InternetExplorer } from './pages/InternetExplorer';
import routes from './components/navigation/routes';
import { Breadcrumbs } from './components/navigation/Breadcrumbs';
import { Switch, Route, useLocation } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import ReactGA from 'react-ga4';
import { Hidden } from '@material-ui/core';
import { EventFilter } from './components/event/EventFilter';
import { isIE } from 'react-device-detect';
import ErrorBanner from './components/error/ErrorBanner';
import { useDispatch, useSelector } from 'react-redux';
import { getSettings } from './store/Settings/Actions';
import { Error } from './store/AppState/Selector';
import { GOOGLE_ANALYTICS_KEY, COOKIE_URL } from './Constants';
import CookieConsent from 'react-cookie-consent';

function App(): JSX.Element {
  const { t } = useTranslation('common');
  const location = useLocation();
  const error = useSelector(Error);

  useEffect(() => {
    ReactGA.initialize(GOOGLE_ANALYTICS_KEY);
  }, []);

  useEffect(() => {
    ReactGA.send({ hitType: "pageview", page: location.pathname, title: `Viewed page: ${location.pathname}` });
  }, [location]);

  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(getSettings());
  }, [dispatch]);

  useEffect(() => {
    const pageTitle = t('global.pageTitle');
    const pageDescription = t('global.pageDescription');
    console.log('Setting page title and description', pageTitle, pageDescription);

    document.title = pageTitle;
    document.querySelector('meta[name="title"]')?.setAttribute('content', `${pageTitle}`);
    document.querySelector('meta[name="description"]')?.setAttribute('content', `${pageDescription}`);
    document.querySelector('meta[property="og:title"]')?.setAttribute('content', `${pageTitle}`);
    document.querySelector('meta[property="og:description"]')?.setAttribute('content', `${pageDescription}`);
    document.querySelector('meta[property="twitter:title"]')?.setAttribute('content', `${pageTitle}`);
    document.querySelector('meta[property="twitter:description"]')?.setAttribute('content', `${pageDescription}`);
    document.querySelector('meta[name="apple-mobile-web-app-title"]')?.setAttribute('content', `${pageTitle}`);
  }, []);

  return (
    <div>
      <Suspense fallback={<div>{t('global.loading')}</div>}>
        <TopNavigation getLangString={t} />
        {isIE ? (
          <InternetExplorer />
        ) : (
          <Switch>
            {routes.map(({ path, Component }, key) => (
              <Route
                exact
                path={path}
                key={key}
                render={props => {
                  const crumbs = routes
                    .filter(({ path }) => props.match.path.includes(path))
                    .map(({ path, name }) => ({
                      path: Object.keys(props.match.params).length
                        ? Object.keys(props.match.params).reduce(
                            (path, param) => path.replace(`:${param}`, props.match.params[param]),
                            path
                          )
                        : path,
                      name,
                    }));
                  return (
                    <React.Fragment>
                      <CookieConsent
                        location="bottom"
                        buttonText={t('cookies.cookieApproval')}
                        expires={30}
                        containerClasses="cookie-consent-container"
                        buttonClasses="cookie-consent-button"
                      >
                        <span>{t('cookies.useOfCookies')} </span>
                        <a className="cookie-consent-link" href={COOKIE_URL} target="_blank" rel="noopener noreferrer">
                          {t('cookies.aboutCookies')}
                        </a>
                      </CookieConsent>
                      {error.isError && <ErrorBanner getLangString={t} errorMessage={error.errorMessage} />}
                      {path === '/events' ? (
                        <React.Fragment>
                          <Hidden mdDown>
                            <EventFilter getLangString={t} />
                          </Hidden>
                          <Breadcrumbs breadcrumbs={crumbs} />
                          <Hidden mdUp>
                            <EventFilter getLangString={t} />
                          </Hidden>
                        </React.Fragment>
                      ) : (
                        <Breadcrumbs breadcrumbs={crumbs} />
                      )}
                      <Component getLangString={t} />
                    </React.Fragment>
                  );
                }}
              />
            ))}
            <Route>
              <NotFound getLangString={t} />
            </Route>
          </Switch>
        )}
      </Suspense>
    </div>
  );
}

export default App;
