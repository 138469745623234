import React from 'react'
import Grid from '@material-ui/core/Grid';
import Button from '@material-ui/core/Button';

export interface IFilterOptionsProps {
    getLangString: (value: string) => string;
    cleanSelectedFilters: (e?: Event) => void;
    addAllFilters: (e?: Event) => void;
}

export const FilterOptions = (props: IFilterOptionsProps): JSX.Element => {

    return (
        <Grid container direction="row" alignItems="center" justifyContent="center" className="filter-panel-options-container">
            <Grid item xs={12} md={6} className="filter-panel-option">
                <Grid item xs={6} sm={6} md={6} lg={6} xl={6}>
                    <Button onClick={() => props.addAllFilters()} className="filter-panel-option-button blue">{props.getLangString('filter.allFilters')}</Button>
                </Grid>
                <Grid item xs={6} sm={6} md={6} lg={6} xl={6}>
                    <Button onClick={() => props.cleanSelectedFilters()} className="filter-panel-option-button white">{props.getLangString('filter.cleanFilter')}</Button>
                </Grid>
            </Grid>
        </Grid>
    )
}