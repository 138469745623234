import { IFilter } from "./Filter";

export const GET_FILTERS_BEGIN = 'GET_FILTERS_BEGIN';
export const GET_FILTERS_SUCCESS = 'GET_FILTERS_SUCCESS';
export const GET_FILTERS_FAILURE = 'GET_FILTERS_FAILURE';
export const UPDATE_SELECTED_FILTERS = 'UPDATE_SELECTED_FILTERS';
export const UPDATE_SELECTED_FILTERS_FAILURE = 'UPDATE_SELECTED_FILTERS_FAILURE';
export const UPDATE_SELECTED_SUBFILTERS = 'UPDATE_SELECTED_SUBFILTERS';
export const UPDATE_SELECTED_SUBFILTERS_FAILURE = 'UPDATE_SELECTED_SUBFILTERS_FAILURE';
export const CLEAN_SELECTED_FILTERS = 'CLEAN_SELECTED_FILTERS';
export const CLEAN_SELECTED_SUBFILTERS = 'CLEAN_SELECTED_SUBFILTERS';
export const SET_SELECTED_FILTER_TITLE = 'SET_SELECTED_FILTER_TITLE';

export interface IFilterState {
    filterList: IFilter[],
    loading: boolean,
    selectedFilters: IFilter[],
    error?: string,
    selectedFilterTitle?: string,
}

interface GetFiltersBeginAction {
    type: typeof GET_FILTERS_BEGIN
}

interface GetFiltersSuccessAction {
    type: typeof GET_FILTERS_SUCCESS,
    payload: IFilter[]
}

interface GetFiltersFailure {
    type: typeof GET_FILTERS_FAILURE,
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    payload: any
}

interface UpdateSelectedFilters {
    type: typeof UPDATE_SELECTED_FILTERS,
    payload: IFilter[]
}

interface UpdateSelectedFiltersFailure {
    type: typeof UPDATE_SELECTED_FILTERS_FAILURE,
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    payload: any
}

interface SetSelectedFilterTitle {
    type: typeof SET_SELECTED_FILTER_TITLE,
    payload: string
}

interface CleanSelectedFilters {
    type: typeof CLEAN_SELECTED_FILTERS,
    payload: IFilter[]
}

export type FilterActionTypes = GetFiltersBeginAction |
     GetFiltersSuccessAction |
     GetFiltersFailure |
     UpdateSelectedFilters | 
     UpdateSelectedFiltersFailure |
     CleanSelectedFilters |
     SetSelectedFilterTitle;

