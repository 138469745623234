import { IFilter } from "../../../store/Filters/Filter";
import { updateSelectedFilters } from "../../../store/Filters/Actions"
import { useDispatch, useSelector } from "react-redux";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { library } from '@fortawesome/fontawesome-svg-core'
import { fas, faTimes, IconName } from '@fortawesome/free-solid-svg-icons'
import { SelectedFilters } from "../../../store/Filters/Selector"
import React, { useState, useEffect } from 'react'
import Button from '@material-ui/core/Button';
import { Link } from "@material-ui/core";
import { Share } from "../../common/share/Share";

export interface IFilterProps {
    getLangString: (value: string) => string;
    filter: IFilter;
    handleSelectedFilter: () => void;
    selectedFilter: IFilter;
    showCategoriesForFilter: () => void;
}
export const FilterButton = (props: IFilterProps): JSX.Element => {

    library.add(fas);
    const [activeFilter, setActiveFilter] = useState(false);
    const [showFilterOption, setShowFilterOption] = useState<boolean>(false);
    const [showOption, setShowOption] = useState<boolean>(false);
    const dispatch = useDispatch();

    const selectedFilters = useSelector(SelectedFilters);

    useEffect(() => {
        handleActive();
    }, [selectedFilters, props.filter])

    const handleClick = () => {
        props.handleSelectedFilter();
        // If the clicked filter is not in the list, add it. Otherwise, remove.
        const inArray = selectedFilters.find(i => i.title === props.filter.title)
        const newSelectedFilters = !inArray
            ? [...selectedFilters, props.filter]
            : selectedFilters.filter(f => f.title !== props.filter.title)
        dispatch(updateSelectedFilters(newSelectedFilters))
        handleActive();
        console.log("click");

    }

    const handleActive = () => {    
        if (selectedFilters.find(i => i.title === props.filter.title)) {
            setActiveFilter(true);
            setShowOption(true);
            if (props.filter.subFilters.length > 0) {
                setShowFilterOption(true);
            }
        } else {
            setActiveFilter(false);
            setShowOption(false);
            setShowFilterOption(false);
        }

    }

    const handleClose = (event: React.MouseEvent<HTMLDivElement, MouseEvent>) => {
        event?.stopPropagation();
        event?.preventDefault();
        props.handleSelectedFilter();
        setShowOption(false);
        setShowFilterOption(false);
    }

    const showSubFilters = (event: React.MouseEvent<HTMLAnchorElement, MouseEvent>) => {
        event?.stopPropagation();
        event?.preventDefault();
        props.showCategoriesForFilter();
    }
    return (
        <React.Fragment>
            <Button
                className={`filter-button ${activeFilter ? props.selectedFilter.title === props.filter.title ? "selected" : "active" : ""}`}
                onClick={handleClick}
                disableTouchRipple={true}
                disableRipple={true}
            >
                {props.selectedFilter.title === props.filter.title &&
                    <div className={'filter-button-close'} onClick={(event: React.MouseEvent<HTMLDivElement, MouseEvent>) => handleClose(event)}>
                        <FontAwesomeIcon icon={faTimes} />
                    </div>
                }
                <div className="filter-button-icon-container">
                    <div className="filter-button-icon">
                        <FontAwesomeIcon icon={['fas', props.filter.icon as IconName]} className={'icon ' + (props.filter.class ? props.filter.class : '')} aria-label={`${props.getLangString('global.icon')} ${props.filter.title}`} />
                    </div>
                    <span className="filter-button-label" >                     
                        {props.filter.title}
                    </span>
                </div>
                {props.selectedFilter.title === props.filter.title &&
                    <div className={`sub-menu-options`}>
                        {showOption &&
                            <React.Fragment>
                                <hr className="sub-menu-divider" />
                                <Share title={props.filter.title} getLangString={props.getLangString} onInfoBox={true} showIcon={false} className={'categories'} />
                                {showFilterOption &&
                                    <Link onClick={(event: React.MouseEvent<HTMLAnchorElement, MouseEvent>) => showSubFilters(event)} className="sub-menu-option-link white">

                                        {'Filtrera'}
                                    </Link>
                                }
                            </React.Fragment>
                        }
                    </div>
                }
            </Button>
        </React.Fragment>
    )
}
