import React from 'react';
import './ThemeControl.scss';
import { faMinus, faPlus } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Button } from '@material-ui/core';

export interface IZoomControlProps {
    zoomLevel: number;
    handleZoom: (zoomLevel: number) => void;
}

export const ZoomControl = (props: IZoomControlProps): JSX.Element => {
    return (
        <div className="zoom control">
            <Button className="plus-control" onClick={() => props.handleZoom(props.zoomLevel + 1)}>
                <FontAwesomeIcon icon={faPlus} className="control-icon" />
            </Button>
            <hr className="solid" />
            <Button className="minus-control" onClick={() => props.handleZoom(props.zoomLevel - 1)}>
                <FontAwesomeIcon icon={faMinus} className="control-icon" />
            </Button>
        </div >
    );
}
