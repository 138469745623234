/* eslint-disable @typescript-eslint/no-explicit-any */
import { IMarker, IPolyline } from "./Marker";

export const GET_MARKERS_BEGIN = 'GET_MARKERS_BEGIN';
export const GET_MARKERS_SUCCESS = 'GET_MARKERS_SUCCESS';
export const GET_MARKERS_FAILURE = 'GET_MARKERS_FAILURE';
export const SIGNALR_CONNECTING = 'SIGNALR_CONNECTING';
export const SIGNALR_DISCONNECTED = 'SIGNALR_DISCONNECTED';
export const SIGNALR_CONNECTED = 'SIGNALR_CONNECTED';
export const SIGNALR_FAILURE = 'SIGNALR_FAILURE';
export const SIGNALR_MARKER_UPDATED = 'SIGNALR_MARKER_UPDATED';
export const UPDATE_POLYLINES = 'UPDATE_POLYLINES';
export const UPDATE_POLYLINES_FAILURE = 'UPDATE_POLYLINES_FAILURE';
export const CLEAN_POLYLINES = 'CLEAN_POLYLINES';
export const CLICKED_ON_POLYLINE = 'CLICKED_ON_POLYLINE';
export const SET_MARKER_DIALOG_OPEN = 'SET_MARKER_DIALOG_OPEN';
export const SET_MARKER_INDEX_DIALOG_OPEN = 'SET_MARKER_INDEX_DIALOG_OPEN';
export const SET_SHOW_ALL_LIVE_MARKERS = 'SET_SHOW_ALL_LIVE_MARKERS';

export interface IMarkerState {
    markersList: IMarker[],
    polylines: IPolyline[],
    loading: boolean,
    connected: boolean,
    clickedOnPolyline: boolean,
    markerWithDialogOpen?: string,
    markerIndexWithDialogOpen?: number,
    showAllLiveMarkers: boolean,
    error?: string
}

interface GetMarkersBeginAction {
    type: typeof GET_MARKERS_BEGIN
}

interface GetMarkersSuccessAction {
    type: typeof GET_MARKERS_SUCCESS,
    payload: IMarker[]
}

interface GetMarkersFailure {
    type: typeof GET_MARKERS_FAILURE,
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    payload: any
}

interface SignalRConnecting {
    type: typeof SIGNALR_CONNECTING
}

interface SignalRConnected {
    type: typeof SIGNALR_CONNECTED
}

interface SignalRDisconnected {
    type: typeof SIGNALR_DISCONNECTED
}

interface SignalRFailure {
    type: typeof SIGNALR_FAILURE,
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    payload: any
}

interface SignalRMarkerUpdated {
    type: typeof SIGNALR_MARKER_UPDATED,
    payload: IMarker
}

interface UpdatePolylines {
    type: typeof UPDATE_POLYLINES,
    payload: any
}

interface UpdatePolylinesFailure {
    type: typeof UPDATE_POLYLINES_FAILURE,
    payload: any
}

interface CleanPolylines {
    type: typeof CLEAN_POLYLINES,
    payload: IPolyline
}

interface ClickedOnPolyline {
    type: typeof CLICKED_ON_POLYLINE,
    payload: boolean
}

interface SetMarkerDialogOpen {
    type: typeof SET_MARKER_DIALOG_OPEN,
    payload: string
}

interface SetMarkerIndexDialogOpen {
    type: typeof SET_MARKER_INDEX_DIALOG_OPEN,
    payload: number
}

interface setShowAllLiveMarkers {
    type: typeof SET_SHOW_ALL_LIVE_MARKERS,
    payload: boolean
}

export type MarkerActionTypes = GetMarkersBeginAction |
    GetMarkersSuccessAction |
    GetMarkersFailure |
    SignalRConnected |
    SignalRConnecting |
    SignalRDisconnected |
    SignalRFailure |
    SignalRMarkerUpdated |
    UpdatePolylines |
    UpdatePolylinesFailure |
    CleanPolylines |
    ClickedOnPolyline |
    SetMarkerDialogOpen |
    SetMarkerIndexDialogOpen |
    setShowAllLiveMarkers;