import React, { useState, useEffect, useRef } from 'react';
import { useDispatch, useSelector } from "react-redux";
import { getFilters, cleanSelectedFilters, updateSelectedFilters, setSelectedFilterTitle } from "../../../store/Filters/Actions";

import Grid from '@material-ui/core/Grid';
import { IFilter } from '../../../store/Filters/Filter';
import { Settings } from '../../../store/Settings/Selector';
import { FilterButton } from './FilterButton'
import { FilterOptions } from './FilterOptions'
import { FilterToggle } from './FilterToggle'

import { library } from '@fortawesome/fontawesome-svg-core'
import { faChevronLeft, fas } from '@fortawesome/free-solid-svg-icons'
import { faCoffee, faMapMarkerAlt, faTimes } from '@fortawesome/free-solid-svg-icons'

import './ThemeMapFilter.scss';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { alpha, Button, styled, Switch } from '@material-ui/core';
import { SubFilters } from './SubFilters';
import { SelectedFilters, Filters } from '../../../store/Filters/Selector';
import { Markers, Polylines, ShowAllLiveMarkers } from '../../../store/Markers/Selector';
import { isNullEmptyOrUndefined } from '../../../utils/helpers/ObjectHelper';
import { cleanPolylines, setShowAllLiveMarkers } from '../../../store/Markers/Actions';
import { useHistory } from 'react-router-dom';
export interface IMapFilterProps {
    getLangString: (value: string) => string;
}

export const MapFilter = (props: IMapFilterProps): JSX.Element => {

    library.add(fas, faCoffee, faMapMarkerAlt, faTimes);
    const dispatch = useDispatch();
    const history = useHistory();

    useEffect(() => {
        dispatch(getFilters());
    }, [dispatch]);

    const settings = useSelector(Settings);
    const filters = useSelector(Filters);
    const selectedFilters = useSelector(SelectedFilters);
    const markers = useSelector(Markers);
    const polylines = useSelector(Polylines);
    const showAllLiveMarkers = useSelector(ShowAllLiveMarkers);
    const [showFilterMenu, setShowFilterMenu] = useState(false);
    const [filtersWithMarkers, setFiltersWithMarkers] = useState<IFilter[]>([]);
    const [selectedFilter, setSelectedFilter] = useState<IFilter>({} as IFilter);
    const [showSubFilters, setShowSubFilters] = useState<boolean>(false);

    useEffect(() => {
        setDefaultFilters();
    }, [settings, filters]);

    useEffect(() => {
        setFiltersToShow();
    }, [markers, filters]);

    const setFiltersToShow = () => {
        const filtersToShow = filters.filter(filter => markers.some(marker => marker.types.some(type => type.title === filter.title)));
        filtersToShow.forEach(filter => {
            filter.subFilters = filter.subFilters.filter(subFilter => markers.some(marker => marker.subTypes?.some(subType => subType.title === subFilter.title)));
        });
        setFiltersWithMarkers(filtersToShow);
    }

    const setDefaultFilters = () => {
        const defaultFilters = filters.filter(filter =>
            settings.defaultFilters.some(defaultFilter => defaultFilter === filter.title));
        if (defaultFilters && selectedFilters.length === 0) {
            dispatch(updateSelectedFilters(defaultFilters));
        }
    }

    const removeFilters = () => {
        const params = new URLSearchParams();
        history.push({ search: params.toString() });
        setSelectedFilter({} as IFilter);
        dispatch(cleanSelectedFilters());
        dispatch(setShowAllLiveMarkers(false));
        dispatch(setSelectedFilterTitle(''));
        if (!isNullEmptyOrUndefined(polylines) && polylines.length > 0) {
            dispatch(cleanPolylines(polylines));
        }
    }

    const removeSubFilters = () => {
        const newFilters = selectedFilters.map(filter => filter.title === selectedFilter.title ? { ...selectedFilter, subFilters: [] } : filter);
        dispatch(updateSelectedFilters(newFilters));
    }

    const addAllFilters = () => {
        dispatch(setSelectedFilterTitle(''));
        dispatch(updateSelectedFilters(filters));
        dispatch(setShowAllLiveMarkers(true));
    }

    const addAllSubFilters = () => {
        const fullSelectedFilter = filters.find(filter => filter.title === selectedFilter.title);
        if (fullSelectedFilter) {
            const newFilters = selectedFilters.map(filter => filter.title === selectedFilter.title ? fullSelectedFilter : filter);
            dispatch(updateSelectedFilters(newFilters));
        }
    }

    const toggleShowAllLiveMarkers = () => {
       dispatch(setShowAllLiveMarkers(!showAllLiveMarkers));
    }

    const toggleFilterMenu = () => {
        setShowFilterMenu(!showFilterMenu);
    }

    const handleSelectedCategory = (filter: IFilter) => {
        const params = new URLSearchParams();

        if (selectedFilter.title !== filter.title &&
            !selectedFilters.find(f => f.title === filter.title)) {
            setSelectedFilter(filter);
            dispatch(setSelectedFilterTitle(filter.title));
            params.append('category', filter.title);
        } else {
            setSelectedFilter({} as IFilter);
            dispatch(setSelectedFilterTitle(''));
        }
        history.push({ search: params.toString() });
    }

    const showSubFiltersForFilter = () => {
        setShowSubFilters(true);
    }

    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    const toggleOnOutsideClick = (filterMenuRef: any, toggleRef: any) => {
        useEffect(() => {
            function handleClickOutside(event: Event) {
                if (filterMenuRef.current && !filterMenuRef.current.contains(event.target) && !toggleRef.current.contains(event.target)) {
                    setShowFilterMenu(false);
                }
            }
            document.addEventListener("mousedown", handleClickOutside);
            return () => {
                document.removeEventListener("mousedown", handleClickOutside);
            };
        }, [filterMenuRef, toggleRef]);
    }

    const filterMenuRef = useRef(null);
    const toggleRef = useRef(null);
    toggleOnOutsideClick(filterMenuRef, toggleRef);

    const RedSwitch = styled(Switch)(({ theme }) => ({
        '& .MuiSwitch-switchBase.Mui-checked': {
          color: "#de0b2c",
          '&:hover': {
            backgroundColor: alpha("#de0b2c", theme.palette.action.hoverOpacity),
          },
        },
        '& .MuiSwitch-switchBase.Mui-checked + .MuiSwitch-track': {
          backgroundColor: "#de0b2c",
        },
      }));
      

    return (
        <React.Fragment>
            {showFilterMenu &&
                <React.Fragment>
                 
                    <div className={` filter-panel`} role="contentinfo" ref={filterMenuRef}>
                        <Grid container direction={'row'} alignContent={'flex-start'} className="filter-panel-container" role="list">
                            <Grid className={`filter-container`} item xs={12}>
                                <Grid container direction={'row'} className="list-container" role="list">
                                    {showSubFilters &&
                                        <React.Fragment>
                                            <Grid item xs={1}>
                                                <Button variant="contained" onClick={() => setShowSubFilters(false)} className="filter-toggle-icon back">
                                                    <FontAwesomeIcon size="lg" icon={faChevronLeft} />
                                                </Button>
                                            </Grid>
                                            <Grid item xs={10}>
                                                <h4 className="filter-title"> {selectedFilter.title}</h4>
                                                <h4 className='filter-description'>{props.getLangString('filter.description')}</h4>
                                                <hr className="title-divider" />
                                            </Grid>
                                        </React.Fragment>
                                    }
                                    {!showSubFilters &&
                                        <Grid item xs={10}>
                                           <RedSwitch checked={showAllLiveMarkers} onClick={toggleShowAllLiveMarkers} className="filter-panel-switch"/>
                                           <span className="switch-label">{props.getLangString('filter.showAllLiveMarkers')}</span>
                                       </Grid>
                                    }
                                    <Grid item xs={showSubFilters ? 1 : 2}>
                                        <Button variant="contained" onClick={() => setShowFilterMenu(false)} className="filter-toggle-icon">
                                            <FontAwesomeIcon size="lg" icon={faTimes} />
                                        </Button>
                                    </Grid>
                                    {showSubFilters ?
                                        <SubFilters getLangString={props.getLangString} selectedFilter={selectedFilter} />
                                        :
                                        <React.Fragment>
                                            {filtersWithMarkers.map((filter: IFilter, index: number) => {
                                                return (
                                                    <Grid key={index} item zeroMinWidth xs={4} sm={2} md={2} lg={2} xl={2} className={`filter-panel-item`} role="listitem">
                                                        <FilterButton
                                                            getLangString={props.getLangString}
                                                            filter={filter}
                                                            key={index}
                                                            handleSelectedFilter={() => handleSelectedCategory(filter)}
                                                            selectedFilter={selectedFilter}
                                                            showCategoriesForFilter={() => showSubFiltersForFilter()}
                                                        />
                                                    </Grid>
                                                )
                                            })}
                                        </React.Fragment>
                                    }
                                </Grid>
                            </Grid>
                            {showSubFilters ?
                                <Grid className="filter-button-container" item xs={12}>
                                    <FilterOptions getLangString={props.getLangString}
                                        cleanSelectedFilters={removeSubFilters}
                                        addAllFilters={addAllSubFilters}
                                        // toggleShowAllLiveMarkers={toggleShowAllLiveMarkers}
                                    />
                                </Grid>
                                :
                                <Grid className="filter-button-container" item xs={12}>
                                    <FilterOptions getLangString={props.getLangString}
                                        cleanSelectedFilters={removeFilters}
                                        addAllFilters={addAllFilters}
                                        // toggleShowAllLiveMarkers={toggleShowAllLiveMarkers}
                                    />
                                </Grid>
                            }
                        </Grid>
                    </div>
                    
                </React.Fragment>
            }
            <div ref={toggleRef}>
                <FilterToggle getLangString={props.getLangString} showingMenu={showFilterMenu} onClick={toggleFilterMenu} />
            </div>
        </React.Fragment >
    );
};
