import { IEvent } from "../../store/Events/Event";
import React from 'react';
import moment from 'moment';
import defaultImage from '../../images/default-img.gif';
import { Link } from "react-router-dom";
import { Grid } from "@material-ui/core";
import ReactGA from 'react-ga4';

export interface IEventTileProps {
    getLangString: (value: string) => string;
    event: IEvent;
}

export const EventTile = (props: IEventTileProps): JSX.Element => {
    // const dateFormatOptions = {
    //     hour12: true,
    //     day: 'numeric',
    //     month: 'long',
    //     year: '2-digit',
    //     minute: '2-digit',
    //     second: '2-digit',
    // };

    // try {
    //     const formatedDate = moment(props.event.start).format('D MMMM   HH:mm');
    //     console.log(formatedDate);
    // } catch (e) {
    //     console.error('Falied to convert date: ', props.event.start);
    //     // console.error(e);
    // }

    return (
        <Grid item xs={12} md={6} lg={4} className="event-tile-container" role="article">
            <Link onClick={() => ReactGA.event({ category: 'Event', action: `Clicked on event: ${props.event.title}` })} to={'/events/' + props.event.id.replaceAll(':', '_') + '/' + props.event.start.toString().replaceAll(':', '_')}>
                <div className="event-tile">
                    <div className="event-date-container">
                        <div className="event-date">
                            {/* {new Intl.DateTimeFormat("sv", dateFormatOptions).format(props.event.start)} */}
                            {moment(props.event.start).format('D MMMM')}
                        </div>
                        <div className="event-date">
                            {moment(props.event.start).format('HH:mm') !== '00:00' && moment(props.event.start).format('HH:mm')}
                        </div>
                    </div>

                    <h3 className="event-title">{props.event.title}</h3>

                    <div className="event-image">
                        <img src={props.event.imageUrl == null ? defaultImage : props.event.imageUrl} alt={`${props.getLangString('global.image')} ${props.event?.title}`} className="img-fluid" />
                    </div>

                </div>
            </Link>
        </Grid>
    );
}