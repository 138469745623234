import React from "react";

export interface IFilterItemProps {
    title: string;
    id: string;
    onDelete: (id: string) => void;
}

export const FilterItem = (props: IFilterItemProps): JSX.Element => {
    return (
        <div className="filter-item">
            {props.title}
            <span className="delete" onClick={() => props.onDelete(props.id)}> x </span>
        </div>
    )
};