/* eslint-disable @typescript-eslint/no-explicit-any */
import { RootState } from "../RootReducer";
import { IMarker, IPolyline } from "./Marker";

export const Markers = (state: RootState) : IMarker[] => state.markers.markersList;
export const MarkerDialogOpen = (state: RootState) : string | undefined => state.markers.markerWithDialogOpen;
export const MarkerIndexDialogOpen = (state: RootState): number | undefined => state.markers.markerIndexWithDialogOpen;
export const Polylines = (state: RootState) : IPolyline[] => state.markers.polylines;
export const ClickedOnPolyline = (state: RootState): boolean => state.markers.clickedOnPolyline;
export const ShowAllLiveMarkers = (state: RootState): boolean => state.markers.showAllLiveMarkers;
