import React, { useEffect, useRef, useState } from 'react';
import { DatePicker } from '../../common/datepicker/DatePicker';
import { Button } from "@material-ui/core";
import {  faChevronDown } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

export interface IDateFilterProps {
    date: Date;
    selectedDate?: Date;
    onChangeDate: (date: Date | null) => void;
    getLangString: (value: string) => string;
}

export const DateFilter = (props: IDateFilterProps): JSX.Element => {
    const [selectedDate, setSelectedDate] = useState<Date | null>(props.selectedDate ?? null);
    const [menuOpen, setMenuOpen] = useState(false);
    const refDateFilter = useRef<HTMLDivElement>(null);

    const handleClickDate = (date: Date | null) => {
        setSelectedDate(date);
        props.onChangeDate(date);
        setMenuOpen(false)
    }

    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    const handleClickRef = (e: { target: any }) => {
        if (refDateFilter.current && !refDateFilter.current?.contains(e.target)) {
            setMenuOpen(false);
        }
    }

    useEffect(() => {
        document.addEventListener("mousedown", handleClickRef);
        return () => {
            document.removeEventListener("mousedown", handleClickRef);
        };
    }, []);

    return (
        <div ref={refDateFilter} className={`select date ${menuOpen ? 'open' : 'closed'}`}>
            <Button onClick={() => setMenuOpen(!menuOpen)}>
               
                {!menuOpen && selectedDate ?
                    selectedDate.toLocaleDateString('sv-SE', { day: 'numeric', month: 'long', year: 'numeric' })
                    :
                    props.getLangString('event.filter.showToDate')
                }
                {!menuOpen && <FontAwesomeIcon icon={faChevronDown} className="chevron-down" />}
            </Button>
            {menuOpen &&
                <React.Fragment>
                    <hr className="solid" />
                    <DatePicker
                        getLangString={props.getLangString}
                        date={selectedDate}
                        setDate={handleClickDate}
                    />

                    <div className="filter-btn-container">
                        <Button onClick={() => handleClickDate(null)} className="filter-btn border-btn">
                            {props.getLangString('event.filter.cleanDateFilter')}
                        </Button>
                    </div>
                </React.Fragment>
            }
        </div>
    );
}
