// utils/useDeviceDetect.js
import React, { useState } from "react";

export default function useDeviceDetect() : { isMobile: boolean } {
    const [isMobile, setMobile] = useState(false);

    React.useEffect(() => {

        const mobile = window.innerWidth <= 991;
        setMobile(mobile);

    }, []);

    return { isMobile };
}