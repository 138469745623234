import React, { useCallback, useEffect, useState } from 'react'
import GoogleMap, { Bounds } from 'google-map-react';
import './ThemeGoogleMaps.scss';
import CustomOption from './CustomStyles'

import { ZoomControl } from '../controls/ZoomControl';
import { PositionControl } from '../controls/PositionControl'
import { Marker } from '../marker/Marker';
import { ClickedOnPolyline, MarkerDialogOpen, Markers, Polylines, ShowAllLiveMarkers } from '../../../store/Markers/Selector';
import { setMarkerDialogAsOpen, getMarkers, getUpdatesForMarkers, setMarkerIndexDialogAsOpen, updatePolylines } from '../../../store/Markers/Actions';
import { useDispatch, useSelector } from 'react-redux';
import { IMarker, IPolyline } from '../../../store/Markers/Marker';
import { GOOGLE_MAP_KEY, GOOGLE_MAP_Position } from '../../../Constants';
import { library } from '@fortawesome/fontawesome-svg-core'
import { fas } from '@fortawesome/free-solid-svg-icons'
import { SelectedFilters, Filters, SelectedFilterTitle } from '../../../store/Filters/Selector';
import { Loader } from '../../common/loader/Loader';
import { MobileInfoBox } from '../marker/MobileInfoBox';
import { Hidden } from '@material-ui/core';
import { useHistory } from 'react-router-dom';
import { setSelectedFilterTitle, updateSelectedFilters } from '../../../store/Filters/Actions';
import { isNullEmptyOrUndefined } from '../../../utils/helpers/ObjectHelper';
import { MarkerIsLive } from '../../../utils/helpers/MarkerHelper';
import { MarkerIsLiveHours } from '../../../store/Settings/Selector';
import { vehicles } from '../../../constants/VehicleConstants';



export interface IGoogleMapsProps {
    getLangString: (value: string) => string;
}

export const GoogleMaps = (props: IGoogleMapsProps): JSX.Element => {

    // Need to add all the icons to be able to load them dynamicly below.
    library.add(fas);

    const dispatch = useDispatch();
    const history = useHistory();

    useEffect(() => {
        dispatch(getMarkers());
        dispatch(getUpdatesForMarkers());
    }, [dispatch]);

    useEffect(() => {
        if (history) {
            if (new URLSearchParams(history.location.search).get('category')) {
                dispatch(setSelectedFilterTitle(new URLSearchParams(history.location.search).get('category') ?? ''));
            }
            if (new URLSearchParams(history.location.search).get('pin')) {
                dispatch(setMarkerDialogAsOpen(new URLSearchParams(history.location.search).get('pin') ?? ''));
            }
            if (new URLSearchParams(history.location.search).get('index')) {
                dispatch(setMarkerIndexDialogAsOpen(Number(new URLSearchParams(history.location.search).get('index')) ?? 0));
            }
        }
    }, [history]);

    const markers = useSelector(Markers);
    const filters = useSelector(Filters);
    const selectedFilters = useSelector(SelectedFilters);
    const markerDialogOpen = useSelector(MarkerDialogOpen);
    const selectedFilterTitle = useSelector(SelectedFilterTitle);
    const polylines = useSelector(Polylines);
    const clickedOnPolyline = useSelector(ClickedOnPolyline);
    const markerIsLiveHours = useSelector(MarkerIsLiveHours);
    const showAllLiveMarkers = useSelector(ShowAllLiveMarkers);

    const [zoomLevel, setZoomLevel] = useState(12);
    const [markersOnMap, setMarkersOnMap] = useState<IMarker[]>([]);
    const [bounds, setBounds] = useState<Bounds>();
    const [center, setCenter] = useState(getMainPosition());
    const [markersLoaded, setMarkersLoaded] = useState(false);
    const [googleMap, setGoogleMap] = useState(null);
    const [googleMaps, setGoogleMaps] = useState(null);
    
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    const handleApiLoaded = (map: any, maps: any) => {
        setGoogleMap(map);
        setGoogleMaps(maps);
    };

    const filterMarkersOnMapBounds = useCallback((markers: IMarker[]) => {
        const filteredMarkers: IMarker[] = [];
        const filterMarkersByFilter = (markers: IMarker[]) => {
            let newFilteredMarkers: IMarker[] = [];
            selectedFilters.forEach(selectedFilter => {
                let markersFromFilter = markers.filter(marker => marker.types.some(type => type.title === selectedFilter.title) || (showAllLiveMarkers && MarkerIsLive(marker, markerIsLiveHours, false)));
                markersFromFilter = markersFromFilter.filter(marker => (
                    ((isNullEmptyOrUndefined(selectedFilter.subFilters) && filters.find(filter => filter.title === marker.title && filter.subFilters.length > 0) ||
                        (filters.find(filter => (filter.title === selectedFilter.title) && filter.subFilters.length === selectedFilter.subFilters.length)))) ||
                    marker.subTypes?.some(subType => selectedFilter.subFilters.some(subFilter => subFilter.title === subType.title))
                ));
                markersFromFilter.forEach(marker => {
                    if (!newFilteredMarkers.includes(marker) && marker.published) {
                        newFilteredMarkers = [...newFilteredMarkers, marker];
                    }
                });
            });
            if (selectedFilters.length === 0 && showAllLiveMarkers)
            {
                const markersFromFilter = markers.filter(marker => MarkerIsLive(marker, markerIsLiveHours, false));
                markersFromFilter.forEach(marker => {
                    if (!newFilteredMarkers.includes(marker) && marker.published) {
                        newFilteredMarkers = [...newFilteredMarkers, marker];
                    }
                });
            }
            setMarkersOnMap(newFilteredMarkers);
            setMarkersLoaded(true);
        }

        const isInsideMapBounds = (marker: IMarker) => {
            if (!bounds || !marker.lat || !marker.lng) {
                return false;
            }

            const { se, ne, nw, sw } = bounds;
            return (
                (marker.lat > se.lat && sw.lat) &&
                (marker.lat < ne.lat && nw.lat) &&
                (marker.lng > nw.lng && sw.lng) &&
                (marker.lng < ne.lng && se.lng)
            )
        }
        markers.forEach(marker => {
            if (isInsideMapBounds(marker)) {
                filteredMarkers.push(marker);
            }
        });

        filterMarkersByFilter(filteredMarkers);
    }, [bounds, selectedFilters, showAllLiveMarkers])

    useEffect(() => {
        if (bounds && markers.length > 0) {
            filterMarkersOnMapBounds(markers);
        }
    }, [bounds, filterMarkersOnMapBounds, markers]);

    useEffect(() => {
        if (bounds && markers.length > 0) {
            filterMarkersOnMapBounds(markers);
        }
    }, [bounds, filterMarkersOnMapBounds, markers]);

    useEffect(() => {
        if (bounds && markers.length > 0) {
            filterMarkersOnMapBounds(markers);
        }
    }, [bounds, filterMarkersOnMapBounds, selectedFilters, markers]);

    useEffect(() => {
        if (!markersOnMap.find(marker => polylines.some(polyline => polyline.id.includes(marker.id)))) {
            handlePolyline();
        }
    }, [markersOnMap]);

    useEffect(() => {
        if (markersLoaded && markers && markerDialogOpen && markerDialogOpen !== 'close') {
            const startMarker = markers.find(m => m.id === markerDialogOpen);
            if (startMarker) {
                const inArray = selectedFilters.find(selectedFilter => startMarker.types.some(type => type.title === selectedFilter.title));
                const filter = filters.find(filter => startMarker.types.find(type => type.title === filter.title));
                if (!inArray && filter) {
                    dispatch(updateSelectedFilters([...selectedFilters, filter]));
                }
                if (startMarker?.lat && startMarker.lng) {
                    setCenter({ lat: startMarker?.lat, lng: startMarker?.lng });
                }
            }
        } else if (markersLoaded && markers && selectedFilterTitle && selectedFilterTitle !== '') {
            const startFilter = filters.find(f => f.title === selectedFilterTitle)
            if (startFilter) {
                dispatch(updateSelectedFilters([startFilter]));
            }
        }
    }, [markersLoaded]);

    const defaultZoom = {
        center: getMainPosition(),
        zoom: 12
    };
    function getMainPosition(): GoogleMap.Coords {        
        
        const position = JSON.parse(GOOGLE_MAP_Position);
        const latFloatValue = parseFloat(position.lat);
        const lngFloatValue = parseFloat(position.lng);        
        return {
            lat: latFloatValue,
            lng: lngFloatValue
        };
    }

    const handleZoom = (zoomLevel: number) => {
        setZoomLevel(zoomLevel);
    };

    const handleCenter = (coords: GoogleMap.Coords) => {
        setCenter(coords);
    };

    const onMapChanged = (changeEvent: GoogleMap.ChangeEventValue) => {
        if (!changeEvent) {
            return;
        }
        if (changeEvent.bounds && JSON.stringify(changeEvent.bounds) !== JSON.stringify(bounds)) {
            setBounds(changeEvent.bounds);
        }
    }

    const handleCustomOption = () => {
        if (markerDialogOpen === "close") {
            return { ...CustomOption, disableDoubleClickZoom: false };
        } else {
            return { ...CustomOption, disableDoubleClickZoom: true };
        }
    }

    const closeInfoBox = () => {
        handlePolyline();
        if (!clickedOnPolyline)
        {
            dispatch(setMarkerDialogAsOpen("close"));
            dispatch(setMarkerIndexDialogAsOpen(0));
            const params = new URLSearchParams();
            history.push({ search: params.toString() });
        }
    };

    const handlePolyline = () => {
        if (!isNullEmptyOrUndefined(polylines) && polylines.length > 0) {
            const polylinesToClean = polylines.filter(poly => !vehicles.some(v => poly.id.includes(v.name)));
            if (!isNullEmptyOrUndefined(polylinesToClean) && polylinesToClean.length > 0) {
                polylinesToClean.forEach((polyline: IPolyline) => {
                    // eslint-disable-next-line @typescript-eslint/no-explicit-any
                    polyline.polylines.forEach((poly: any) => {
                        if (!isNullEmptyOrUndefined(poly)) {
                            poly.setMap(null);
                        }
                    })
                })
                const newPolylines = polylines.filter(polyline => !polylinesToClean.some(poly => poly.id === polyline.id));
                dispatch(updatePolylines(newPolylines));
            }
        }
    }

    return (
        <div id="map-container" role="main" aria-label={props.getLangString('filter.map')}>
            {!markersLoaded &&
                <div className="loading-container">
                    <Loader getLangString={props.getLangString} />
                </div>
            }
            <GoogleMap
                bootstrapURLKeys={{ key: GOOGLE_MAP_KEY }}
                defaultCenter={defaultZoom.center}
                defaultZoom={defaultZoom.zoom}
                onZoomAnimationStart={handleZoom}
                onDrag={handleCenter}
                zoom={zoomLevel}
                center={center}
                options={handleCustomOption()}
                onClick={closeInfoBox}
                onChange={onMapChanged}
                yesIWantToUseGoogleMapApiInternals={true}
                onGoogleApiLoaded={({ map, maps }) => handleApiLoaded(map, maps)}
            >
                {markersLoaded &&
                    markersOnMap.map((m: IMarker, index: number) =>
                        <Marker
                            getLangString={props.getLangString}
                            lat={m.lat}
                            lng={m.lng}
                            zoomLevel={zoomLevel}
                            marker={m}
                            key={index}
                            map={googleMap}
                            maps={googleMaps}
                            isVehicle={m.types.find(type => type.title === "Turer") ? true : false}
                        />
                    )}
            </GoogleMap>
            <Hidden smUp>
                <MobileInfoBox
                    getLangString={props.getLangString}
                    markers={markersOnMap}
                    map={googleMap}
                    maps={googleMaps}
                />
            </Hidden>

            <ZoomControl
                zoomLevel={zoomLevel}
                handleZoom={(level) => handleZoom(level)}
            />
            <PositionControl handleCenter={(coords) => handleCenter(coords)} />
        </div>
    )
}
