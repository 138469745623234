import React from 'react';
import { Container } from '@material-ui/core';
import { EventList } from '../components/event/EventList'

export interface IEventsProps{
    getLangString: (value: string) => string;
}

export const Events = (props: IEventsProps) : JSX.Element => {
    return (
        <Container role="main">
            <EventList getLangString={props.getLangString}/>
        </Container>
    );
};