import { faCheckCircle, faCircle } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import React, { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { IFilter, ISubFilter } from '../../../store/Filters/Filter';
import { SelectedFilters } from '../../../store/Filters/Selector';

export interface ISubFilterProps {
    getLangString: (value: string) => string;
    subFilter: ISubFilter;
    handleClick: () => void;
    selectedFilter: IFilter | undefined;
}

export const SubFilter = (props: ISubFilterProps): JSX.Element => {

    const [activeSubFilter, setActiveSubFilter] = useState<boolean>(false);
    const selectedFilters = useSelector(SelectedFilters);

    useEffect(() => {
        handleActive();
    }, [props.subFilter, selectedFilters]);

    const handleActive = () => {
        if (props.selectedFilter &&
            props.selectedFilter.subFilters.find(i => i.title === props.subFilter.title)) {
            setActiveSubFilter(true)
        } else {
            setActiveSubFilter(false);
        }
    };

    return (
        <h4 className="category-items-title"  onClick={() => props.handleClick()} >
            <FontAwesomeIcon 
                icon={activeSubFilter ? faCheckCircle : faCircle} 
                className={`check-circle-icon ${activeSubFilter ? '' : 'circle-border'}`}   
            />
            {props.subFilter.title}
        </h4>
    );
};