import React from 'react';
import Grid from '@material-ui/core/Grid';
import { SubFilter } from './SubFilter';
import { IFilter, ISubFilter } from '../../../store/Filters/Filter';
import { useDispatch, useSelector } from 'react-redux';
import { SelectedFilters } from '../../../store/Filters/Selector';
import { updateSelectedFilters } from '../../../store/Filters/Actions';

export interface ISubfiltersProps {
    getLangString: (value: string) => string;
    selectedFilter: IFilter;
}

export const SubFilters = (props: ISubfiltersProps): JSX.Element => {

    const dispatch = useDispatch();
    const selectedFilters = useSelector(SelectedFilters);

    const handleClick = (subFilter: ISubFilter) => {
        const selectedFilter = selectedFilters.find(filter => filter.title === props.selectedFilter.title);
        const isSelected = selectedFilter?.subFilters.some(filter => filter.title === subFilter.title);
        const newSelectedFilters = !isSelected
             ? selectedFilters.map(f => f.title === props.selectedFilter.title ? {...f, subFilters : [...f.subFilters,  subFilter]} : f)
             : selectedFilters.map(f => f.title === props.selectedFilter.title ? {...f, subFilters : selectedFilter ? selectedFilter.subFilters.filter(s => s.title !== subFilter.title) : []} : f)
            dispatch(updateSelectedFilters(newSelectedFilters))
    }

    return (
        <React.Fragment>
        <Grid container direction={'row'} role="list" rowGap={3}>
                {props.selectedFilter.subFilters && props.selectedFilter.subFilters.map((subfilter: ISubFilter, index: number) => {
                    return (
                        <Grid item key={index} xs={12} md={6} className="category-items">
                            <SubFilter 
                                getLangString={props.getLangString} 
                                subFilter={subfilter} 
                                handleClick={() => handleClick(subfilter)} 
                                selectedFilter={selectedFilters.find(filter => filter.title === props.selectedFilter.title)}
                            />
                        </Grid>
                    )
                })
            }
        </Grid>
        </React.Fragment>
    );
};