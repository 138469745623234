import React from "react";
import { Helmet } from "react-helmet"
import { useTranslation } from 'react-i18next';
import { GetDefaultMapImage } from "../../../utils/helpers/ImageHelper";

interface ISEOProps {
    title?: string;
    description?: string;
    imageUrl?: string;
}

export const SEO = (props: ISEOProps): JSX.Element => {
    const { t } = useTranslation('common');
    const pageTilge =  t('global.pageTitle');
    const pageDescription = t('global.pageDescription');
    const title = props.title ?? pageTilge;
    const description = props.description ?? pageDescription;
    const imageUrl = props.imageUrl ?? GetDefaultMapImage();
    return (
        <Helmet>
            <meta name="title" content={title} />
            <meta name="description" content={description} />
            <meta name="image" content={imageUrl} />

            {/* Open Graph - Facebook */}
            <meta property="og:type" content="website" />
            <meta property="og:url" content="" />
            <meta property="og:title" content={title} />
            <meta property="og:description" content={description} />
            <meta property="og:image" content={imageUrl} />

            {/* Twitter */}
            <meta property="twitter:card" content="summary_large_image" />
            <meta property="twitter:url" content="" />
            <meta property="twitter:title" content={title} />
            <meta property="twitter:description" content={description} />
            <meta property="twitter:image" content={imageUrl} />
        </Helmet>
    )
}

