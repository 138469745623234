import axios from 'axios';
import { API_ROOT } from '../../Constants';
import { ThunkAction } from 'redux-thunk'
import { RootState } from '../RootReducer';
import { Action } from 'redux';
import * as SettingsActions from './ActionTypes'

export const getSettings = (): ThunkAction<void, RootState, unknown, Action<string>> => async dispatch => {
    try {
        dispatch({ type: SettingsActions.GET_SETTINGS_BEGIN });

        const result = await axios.get(`${API_ROOT}/Setting`);
        dispatch({
            type: SettingsActions.GET_SETTINGS_SUCCESS,
            payload: result.data
        });
    } catch (error) {
        console.error(error);
        dispatch({ type: SettingsActions.GET_SETTINGS_FAILURE, payload: error })
    }
} 