import React, { useRef, useEffect } from 'react';
import { useParams } from 'react-router-dom';

import './ThemeReport.scss';

declare global {
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    interface Window { tableau: any; }
}

window.tableau = window.tableau || {};

// Hack to make the Tableau embed code work inte TypeScript.. 
declare const window: Window;
interface ParamTypes {
    id: string
}
export const Report = (): JSX.Element => {
    const { tableau } = window;
    const ref = useRef(null);
    const { id } = useParams<ParamTypes>();

    useEffect(() => {
        const url = 'https://public.tableau.com/views/' + decodeURIComponent(id); // id.replaceAll('|', '/'); //?:language=en&:display_count=y&publish=yes&:origin=viz_share_link
        new tableau.Viz(ref.current, url);
    }, [tableau.Viz, id]);

    return (
        <div ref={ref} className="report-container"></div>
    )
}