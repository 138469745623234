import { THEME_KEY } from '../../Constants';

// Define the array of URLs
export const urlArray = THEME_KEY === 'olofstrom'
    ? [
        { id: 1, name: 'Olofstrom.se', url: 'https://olofstrom.se/' },
        { id: 2, name: 'Visitolofstrom.se', url: 'http://www.visitolofstrom.se' },
      ]
    : [
        { id: 1, name: 'Ronneby.se', url: 'https://ronneby.se' },
        { id: 2, name: 'Visitronneby.se', url: 'http://www.visitronneby.se' },
      ];
export const menuMapItems = THEME_KEY === 'olofstrom'
    ? [
        {
          id: '1',
          title: 'Olofström',
          url: 'https://live.olofstrom.se',
          active: false,
        },
        {
          id: '2',
          title: 'Ronneby',
          url: 'https://live.ronneby.se',
          active: true,
        },
      ]
    : [
        {
          id: '1',
          title: 'Ronneby',
          url: 'https://live.ronneby.se',
          active: false,
        },
        {
          id: '2',
          title: 'Olofström',
          url: 'https://live.olofstrom.se',
          active: true,
        },
      ];
