import { IError } from './Error';

export const SET_ERROR = 'SET_ERROR';
export const SET_ERROR_FAILURE = 'SET_ERROR_FAILURE';
export const CLEAR_ERROR = 'CLEAR_ERROR';
export const CLEAR_ERROR_FAILURE = 'CLEAR_ERROR_FAILURE';

export interface IAppState {
    errorSummary: IErrorSummary;
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    error?: any;
}

export interface IErrorSummary {
    isError: boolean;
    errorMessage: string;
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    errors: any[];
}

interface SetError {
    type: typeof SET_ERROR,
    payload: IError;
}

interface SetErrorFailure {
    type: typeof SET_ERROR_FAILURE,
    payload: string
}

interface ClearError {
    type: typeof CLEAR_ERROR,
}

interface ClearErrorFailure {
    type: typeof CLEAR_ERROR_FAILURE,
    payload: string
}

export type AppStateActionTypes = SetError | SetErrorFailure | ClearError | ClearErrorFailure;